<mat-chip
    #c="matChip"
    (removed)="removeRole()"
    [class.new-role-chip]="newRole&&!toDelete"
    [class.role-failed]="failed"
    [class.to-delete]="toDelete"
    data-automation="newRoleChip"
>
    <span class="chip-text" [attr.data-automation]="role.displayLabel" [attr.id]="role.businessRole + '_' + spaceId"
    matTooltip="{{role.displayLabel}}" matTooltipPosition="above">{{
        role.displayLabel
    }}</span>

    <mat-icon
        matChipRemove
        *ngIf="isEdit && !(loading || success || saving || toDelete) && showDelete"
        [attr.data-automation]="'delrole_' + spaceId + '_' + role.businessRole"
        >close</mat-icon
    >
    <div *ngIf="loading" class="chip-spinner"><mat-spinner diameter="18"></mat-spinner></div>
    <mat-icon *ngIf="success">done</mat-icon>
    <div
        *ngIf="failed"
        class="role-retry"
        title="{{ 'user.edit.tooltip.save_to_retry' | translate }}"
        data-automation="retrySubmitRoleBtn"
    >
        <mat-icon class="error-icon">error_outline</mat-icon>
    </div>
    <mat-icon class="addIcon" *ngIf="toDelete" matChipRemove>add</mat-icon>
</mat-chip>
