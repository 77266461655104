import { Pipe, PipeTransform } from '@angular/core';

/*
 * Search for primary organization name
 * Usage:
 *   organization | primaryOrg
 */
@Pipe({ name: 'primaryOrg' })
export class OrganizationPrimaryPipe implements PipeTransform {
  transform(organizations: any[], key: string): string {
    return organizations.find(org => {
        return org.primary === true;
    }).organizationName || " ";
  }
}
