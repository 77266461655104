<div id="location-roles" data-automation="location-roles">
    <div class="location-selection">
        <!-- tabs here-->
        <mat-tab-group animationDuration="10ms" (selectedIndexChange)="onChangeSelectedTab($event)">
            <!--organization hirarchy tab-->
            <mat-tab>
                <ng-template mat-tab-label>
                    <span (click)="deselectAllLocations()">{{ 'user.edit.locations.organizations' | translate }}</span>
                </ng-template>
            </mat-tab>

            <!--market hirarchy tab-->
            <mat-tab>
                <ng-template mat-tab-label>
                    <span (click)="deselectAllLocations()">{{ 'user.edit.locations.market' | translate }}</span>
                </ng-template>
            </mat-tab>

            <!--Admin groups tab-->
            <mat-tab>
                <ng-template mat-tab-label>
                    <span (click)="deselectAllLocations()">{{ 'user.edit.locations.admin_groups' | translate }}</span>
                </ng-template>
            </mat-tab>

            <!--Dr groups tab-->
            <mat-tab>
                <ng-template mat-tab-label>
                    <span (click)="deselectAllLocations()">{{ 'user.edit.locations.dr_groups' | translate }}</span>
                </ng-template>
            </mat-tab>
        </mat-tab-group>

        <!-- search -->
        <div [ngClass]="loadingHierarchy ? 'search-disabled dr-search' : 'search-enabled dr-search'">
            <mat-icon class="search-icon">search</mat-icon>
            <input
                class="context-search-input"
                data-automation-name="search-text-field"
                [(ngModel)]="searchText"
                [disabled]="loadingHierarchy"
                placeholder="{{ 'user.placeholder.search' | translate }}"
                autofocus
            />
        </div>

        <div *ngIf="currentTab == 0" class="radio-btn-container">
            <mat-radio-group
                [value]="showOnlyPrimaryOrg"
                (change)="switchHierarchy($event)"
                aria-label="Select an option"
                [disabled]="loadingHierarchy"
            >
                <mat-radio-button [value]="true">{{ 'user.edit.show_only_primary' | translate }}</mat-radio-button>
                <mat-radio-button [value]="false">{{ 'user.edit.show_all_orgs' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>

        <div *ngIf="!loadingHierarchy" class="tree-container">
            <!-- org list -->
            <div class="context-list">
                <ng-container>
                    <kendo-treeview
                        [nodes]="filteredNodes"
                        textField="displayLabel"
                        [(checkedKeys)]="selectedKeys"
                        [(expandedKeys)]="expandedKeys"
                        [checkBy]="'id'"
                        [expandBy]="'id'"
                        (checkedChange)="handleSelection($event)"
                        [kendoTreeViewCheckable]="checkboxSettings"
                        [isSelected]="isDisabled"
                        kendoTreeViewExpandable
                        kendoTreeViewHierarchyBinding
                        data-automation="locationTreeSelector"
                        [isVisible]="isVisible"
                        [hasChildren]="hasChildren"
                        [children]="fetchChildren"
                        (expand)="handleExpand($event)"
                        kendoTreeViewLoadMore
                        [pageSize]="40"
                    >
                        <ng-template data-type="dataItem.type" kendoTreeViewNodeTemplate let-dataItem>
                            <mat-icon class="tree-icon">{{ getIconByType(dataItem) }}</mat-icon>
                            <span class="dataItem">{{ dataItem.displayLabel }}</span>
                        </ng-template>
                    </kendo-treeview>
                </ng-container>
            </div>
        </div>

        <div *ngIf="loadingHierarchy" class="spaces-loading-spinner">
            <mat-spinner color="primary" diameter="40"></mat-spinner>
        </div>
        <!--buttons-->
        <div class="btn-container">
            <button
                mat-button
                color="primary"
                [disabled]="loadingHierarchy"
                (click)="selectAllLocations()"
                data-automation="selectAllLocations"
            >
                {{ 'user.edit.select_all' | translate }}
            </button>
            <button
                mat-button
                color="primary"
                [disabled]="loadingHierarchy"
                (click)="deselectAllLocations()"
                data-automation="deselectAllLocations"
            >
                {{ 'user.edit.clear_selection' | translate }}
            </button>
        </div>

        <!--close location selection-->
    </div>
    <div class="roles-selection">
        <div *ngIf="!loadingRoles" class="roles-list">
            <h6 class="roles-title" data-automation="selectRoleHeader">{{ 'user.edit.select_roles' | translate }}</h6>
            <mat-form-field class="example-chip-list" floatLabel="always">
                <mat-label></mat-label>
                <mat-chip-list #chipList aria-label="Role selection" [formControl]="chipsFormControl">
                    <input
                        placeholder="{{ 'user.edit.placeholder.click_or_type' | translate }}"
                        #roleInput
                        class="ellipsis"
                        [formControl]="roleCtrl"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        data-automation="role_search_input"
                    />
                </mat-chip-list>

                <mat-autocomplete #auto="matAutocomplete" (click)="$event.stopPropagation()">
                    <mat-option
                        *ngFor="let role of filteredRoles$ | async"
                        [value]="role.name"
                        data-automation="role_search_option"
                        (click)="$event.stopPropagation()"
                        (selected)="$event.stopPropagation()"
                        style="padding: 0 !important;"
                        [disabled]="checkRoleDisabled(role.name)"
                    >
                        <mat-checkbox #roleCheckbox class="role-checkbox"
                        (click)="checkRoleDisabled(role.name) ? $event.stopPropagation() : selectRole($event, role.name)"
                        [disabled]="checkRoleDisabled(role.name)"
                        [matTooltip]="checkRoleDisabled(role.name) ? ('user.edit.roles_notifications_disabled' | translate) : ''">
                            {{ role.displayLabel }}
                        </mat-checkbox>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-chip-list>
                <mat-chip
                    *ngFor="let role of selectedRoles"
                    (removed)="removeRole(role.name)"
                    class="ellipsis selected-role"
                    data-automation="selected_role"
                    title="{{ role.displayLabel }}"
                >
                    <span class="chip-text" data-automation="{{ role.displayLabel }}">{{ role.displayLabel }}</span>
                    <mat-icon matChipRemove *ngIf="removable">close</mat-icon>
                </mat-chip>
            </mat-chip-list>

            <br class="clearfix" />

            <button
                class="icon-btn x-btn-primary apply-roles"
                (click)="applyRoles()"
                data-automation="applyRolesBtn"
                [disabled]="!selectedRoles.length || !selectedKeys.length"
            >
                <mat-icon *ngIf="!applyingRoles">done</mat-icon>
                <div *ngIf="applyingRoles" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text">{{ 'user.edit.button.add_roles' | translate }}</span>
            </button>
            <mat-error *ngIf="illegalSubs.length > 0">
                {{ 'user.edit.active_roles.failed' | translate }} {{ illegalSubs.join(', ') }}
            </mat-error>
        </div>

        <div *ngIf="loadingRoles" class="spaces-loading-spinner">
            <mat-spinner color="primary" diameter="40"></mat-spinner>
        </div>
    </div>
    <div class="clearfix"></div>
</div>
