import { UserService } from '../../../shared/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import {
  Component,
  Input,
  OnInit,
  ViewChildren,
  QueryList,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Subscription, Observable, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { mapTo } from 'rxjs/operators';
import { SpaceRole, UserRole } from 'src/app/shared/model/business-role.model';
import { SidenavService } from '../../../shared/services/sidenav.service';
import { BusinessRolesService } from '../../../shared/services/business-roles.service';
import { RoleChipComponent } from '../role-chip/role-chip.component';
import { DeleteRolesDialogComponent } from 'src/app/dialogs/delete_roles/delete-roles-dialog.component';
import { PermissionService } from '../../../shared/services/permissions.service';
import {UserStatus} from "../../../shared/model/user.model";
import {UserStatusService} from "../../../shared/services/user-status.service";

@Component({
  selector: 'app-active-location',
  templateUrl: './active-location.component.html',
  styleUrls: ['./active-location.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveLocationComponent implements OnInit, OnDestroy {
  @Input() userId: string;
  @Input() contactId: string;
  @Input() mode: string;
  @Input() spaceWithRoles: SpaceRole;
  @Input() roles: UserRole[];
  @Input() sync: boolean;
  @Input() allActiveRoles: SpaceRole[] = [];
  @Output() removeLocationEvent = new EventEmitter<string>();
  @Output() onRoleRemoval = new EventEmitter<string>();
  @Output() unRemoveRoleEvent = new EventEmitter<number>();

  @ViewChildren(RoleChipComponent) roleChips: QueryList<RoleChipComponent>;

  private rolesList: UserRole[] = [];

  EDIT = 'edit';
  subscriptions: Subscription[] = [];
  failed: boolean;
  success: boolean;

  hiddenButtons: number = null;
  visibleButtons: number = null;
  panelClosedState = true;
  showDelete = false;

  toDelete = false;

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  @ViewChildren('roleButton', { read: ElementRef })
  private roleButtons: QueryList<ElementRef>;
  public userStatus: UserStatus;
  private windowResizeEvent$: Observable<any>;
  private sidenavOpenStatus$: Observable<boolean>;

  constructor(
    private cdRef: ChangeDetectorRef,
    private sidenavService: SidenavService,
    private businessRolesService: BusinessRolesService,
    private dialog: MatDialog,
    public userService: UserService,
    public userStatusService: UserStatusService,
    private permissionService: PermissionService,
  ) {}

  async ngOnInit() {
    this.spaceWithRoles.rolesToDelete = [];

    this.windowResizeEvent$ = fromEvent(window, 'resize').pipe(mapTo(true), debounceTime(100));
    this.sidenavOpenStatus$ = this.sidenavService.sidenavOpenStatus$;
    const permissionSub = this.permissionService.permissions$.subscribe(permissions => {
      if (permissions && this.permissionService.canDeleteRoles()) {
        this.showDelete = true;
      }
    });

    const statusSub = this.userStatusService.userStatus$.subscribe(status => {
      this.userStatus = status;
    });

    const errorRolesSub = this.businessRolesService.errorRoles$.subscribe(errorRoles => {
      errorRoles.forEach(role => {
        if (role.entity.id === this.spaceWithRoles.id) {
          if(this.spaceWithRoles.activeRoles.includes(role.role)) {
            this.removeCurrentRole(role.role);
          }
          const chip = this.roleChips.find(chip => chip.role.businessRole === role.role.businessRole);
          if (chip && !this.spaceWithRoles.newRoles.includes(role.role)) {
            chip.updateStatus('FAILED');
            chip.toDelete = true;
          }
        }
      });
    });

    this.subscriptions.push(permissionSub, statusSub, errorRolesSub);
  }

  ngAfterViewInit() {
    const newSpacesWithRolesSub = this.businessRolesService.newSpacesWithRoles$.subscribe(newSpaceWithRoles => {
      this.addNewRoles(newSpaceWithRoles);
    });

    this.calcButtons();
    const windowResizeEventSub = this.windowResizeEvent$.subscribe(() => {
      this.calcButtons();
    });
    const sidenavOpenStatusSub = this.sidenavOpenStatus$.subscribe(() => {
      if (this.sidenavService.getMode() === 'side') {
        this.calcButtons();
      }
    });

    this.subscriptions.push(...[newSpacesWithRolesSub, windowResizeEventSub, sidenavOpenStatusSub]);
  }

  addNewRoles(newSpaceWithRoles) {
    if (newSpaceWithRoles && newSpaceWithRoles.length) {
      const matchingSpace = newSpaceWithRoles.find(newSpaceWithRole => {
        return newSpaceWithRole.id === this.spaceWithRoles.id;
      });
      if (matchingSpace) {
        const newRoles = matchingSpace.newRoles.filter(
          newRole => !this.spaceWithRoles.activeRoles.find(activeRole => activeRole.businessRole === newRole.name),
        );
        this.spaceWithRoles.newRoles = [...new Set([...this.spaceWithRoles.newRoles, ...newRoles])];
        this.cdRef.detectChanges();
      }
    }
  }

  removeNewRole(role): void {
    if (this.isTheLastRole()) {
      this.removeLocation();
    } else {
      const index = this.spaceWithRoles.newRoles.indexOf(role);
      if (index >= 0) {
        this.spaceWithRoles.newRoles.splice(index, 1);
      }
    }
    this.onRoleRemoval.emit("new");
    this.businessRolesService.updateRoleRequirements$.next([]);
  }

  resetNewRoles() {
    this.spaceWithRoles.newRoles = [];
    this.spaceWithRoles.rolesToDelete.forEach(role => {
      const chip = this.roleChips.find(chip => chip.role.businessRole === role.businessRole);
      if (chip) {
        chip.toDelete = false;
      }
    });
    this.spaceWithRoles.activeRoles.forEach(role => {
      const chip = this.roleChips.find(chip => chip.role.businessRole === role.businessRole);
      if (chip) {
        chip.toDelete = false;
      }
    });

    this.spaceWithRoles.rolesToDelete = [];
    this.toDelete = false;
    if(this.spaceWithRoles.activeRoles.length === 0) {
      this.removeLocation();
    }
    this.onRoleRemoval.emit("resetNew");
    this.businessRolesService.updateRoleRequirements$.next([]);
  }

  removeCurrentRole(role) { 
    const chip = this.roleChips.find(chip => chip.role === role);
    if(!this.spaceWithRoles.rolesToDelete.includes(role) && (!chip || !chip.toDelete)) {
      if (this.isTheLastRole()) {
        this.removeLocation();
      }
      else {
        this.spaceWithRoles.rolesToDelete.push(role);
        if (chip) {
          chip.toDelete = true;
        }
        this.onRoleRemoval.emit("current");
      }
    }
    else {
      this.toDelete = false;
      this.spaceWithRoles.rolesToDelete = this.spaceWithRoles.rolesToDelete.filter(r => r !== role);
      this.unRemoveRoleEvent.emit(1);
      const chip = this.roleChips.find(chip => chip.role === role);
      if (chip) {
        chip.toDelete = false;
      }
    }
    
    this.businessRolesService.updateRoleRequirements$.next([]);
  }

  isTheLastRole() {
    let activeRoles = this.spaceWithRoles.activeRoles.length || 0;
    if (activeRoles > 0) activeRoles -= this.spaceWithRoles.rolesToDelete.length;
    const newRoles = this.spaceWithRoles.newRoles.length || 0;
    return activeRoles + newRoles == 1;
  }

  openDeleteRolesDialog(role: UserRole) {
    return this.dialog.open(DeleteRolesDialogComponent, {
      width: '400px',
      data: {
        roles: [role],
        entity: this.spaceWithRoles,
        deleteEntityAccess: false,
        userId: this.userId,
        contactId: this.contactId,
        sync: this.sync,
        status: this.userStatus,
        allSpaceRoles: this.allActiveRoles,
      },
      disableClose: true,
    });
  }

  removeLocation() {
    this.toDelete = true;
    this.removeLocationEvent.emit(this.spaceWithRoles.id);

    //Reset roles to be added
    this.spaceWithRoles.newRoles = [];
    this.businessRolesService.updateRoleRequirements$.next([]);
    
    //Set active roles to be deleted
    this.spaceWithRoles.rolesToDelete = [...this.spaceWithRoles.activeRoles];
    this.roleChips.forEach(chip => {
      chip.toDelete = true;
    });
  }

  unRemoveLocation() {
    this.toDelete = false;
    this.unRemoveRoleEvent.emit(this.spaceWithRoles.rolesToDelete.length);
    this.spaceWithRoles.rolesToDelete = [];
    this.roleChips.forEach(chip => {
      chip.toDelete = false;
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  getSpaceRoleStatus(saveRoles, role, spaceId, spaceType) {
    return saveRoles.get(JSON.stringify({ role: role, entityId: spaceId, entityType: spaceType })) || '';
  }

  panelClose() {
    this.panelClosedState = true;
    this.calcButtons();
  }

  /** Role buttons dynamic rendering */
  private initButtons() {
    this.hiddenButtons = 0;
    this.visibleButtons = this.rolesList.length;
    this.cdRef.detectChanges();
  }

  private calcButtons() {
    if (!this.panelClosedState) return;

    this.initButtons();
    this.cdRef.detach();

    const elementList: ElementRef<any>[] = this.roleButtons.toArray();
    const rowLength: number = this.getRowLength(elementList);

    this.hiddenButtons = rowLength < this.rolesList.length ? this.rolesList.length - rowLength : 0;
    this.visibleButtons = this.hiddenButtons ? rowLength : this.rolesList.length;

    this.cdRef.reattach();
    this.cdRef.detectChanges();
  }

  private getRowLength(elementList: ElementRef<any>[]): number {
    if (!elementList.length) return null;

    const nextRowIndex: number = this.getNextRowIndex(elementList);
    if (!nextRowIndex) {
      return elementList.length;
    }
    return nextRowIndex > 1 ? nextRowIndex - 1 : 1;
  }

  private getNextRowIndex(elementList: ElementRef<any>[]): number {
    if (!elementList.length) return null;

    const rect: DOMRect = elementList[0].nativeElement.getBoundingClientRect();
    const topLineOffset: number = rect.top;
    let buttonItemIndex = 0;

    for (const buttonItem of this.roleButtons) {
      const rect: DOMRect = buttonItem.nativeElement.getBoundingClientRect();
      if (rect.top > topLineOffset) {
        return buttonItemIndex;
      }
      buttonItemIndex++;
    }

    return null;
  }
}
