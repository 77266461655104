import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MenuData, Context, ContextSelectorService, Space } from 'ngx-global-nav';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from './shared/services/sidenav.service';
import { NavService } from './shared/services/global-nav.service';
import { CookieService } from './shared/services/cookie.service';
import { Permission, PermissionType } from './shared/model/permission.model';
import { PermissionService } from './shared/services/permissions.service';
import { SpaceService } from './shared/services/space.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('sidenav') public sidenav: MatSidenav;
  readonly menuData$: Observable<MenuData>;
  sidenavMode: string;
  sidenavOpen = false;
  navUrl = '';
  showSidenav = false;

  permissions: Permission[];

  constructor(
    private translate: TranslateService,
    private navService: NavService,
    private ngxCookieService: NgxCookieService,
    private cookieService: CookieService,
    private contextSelectorService: ContextSelectorService,
    private sidenavService: SidenavService,
    private httpClient: HttpClient,
    private permissionService: PermissionService,
    private spaceService: SpaceService
  ) {
    this.getNavUrl();
    this.sidenavMode = window.innerWidth > 1200 ? 'side' : 'over';

    const { defaults } = environment;
    const locale = this.ngxCookieService.getI18NLocale() || defaults.locale;
    const brand = this.ngxCookieService.getI18NBrand() || defaults.brand.toUpperCase();
    this.translate.setDefaultLang(`${defaults.locale}.${defaults.brand.toUpperCase()}`);
    this.translate.use(`${locale}.${brand.toUpperCase()}`).subscribe(() => {
      this.translate.get('APP.TITLE').subscribe(result => {
        document.title = result;
      });
    });

    this.spaceService.contextList$.subscribe((spaces: Space[]) => {
      this.contextSelectorService.setContextList(spaces);
    });

    this.contextSelectorService.currentContext$.subscribe((orgs: Context[]) => {
      console.log('Org ID: ', orgs[0].id);
      console.log('Org Label: ', orgs[0].label);
    });

    this.sidenavService.hideSideNav$.subscribe(hideSideNavFlag => {
      this.showSidenav = !hideSideNavFlag;
    });

    this.permissionService.permissions$.subscribe((permissions: PermissionType) => {
      if (permissions) {
        if (this.permissionService.canSeeOrgUsers()) {
          this.showSidenav = true;
          this.sidenavOpen = true;
        } else {
          this.showSidenav = false;
          this.sidenavOpen = false;
        }
      }
    });
  }

  async getNavUrl() {
    this.navUrl = await this.navService.getNavUrl();
  }

  async ngOnInit() {
    await this.spaceService.getContextList();
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.sidenavMode = event.target.innerWidth > 1280 ? 'side' : 'over';
  }
}
