<div class="active-location" [attr.data-automation]="'active_location_roles_panel_' + spaceWithRoles.id">
    <div
        class="location-type"
        [attr.data-automation]="'location_type_' + (spaceWithRoles.spaceType || spaceWithRoles.space_type)"
    >
        {{ spaceWithRoles.spaceType || spaceWithRoles.space_type || spaceWithRoles.drType }}
    </div>
    <div
        class="location-name"
        [attr.data-automation]="'location_name_' + (spaceWithRoles.displayLabel || spaceWithRoles.display_label)"
    >
        <!-- Tooltip for location name. The new span was inserted to avoid problems with padding/margins from the table structure. -->
        <span 
        matTooltip="{{ spaceWithRoles.displayLabel || spaceWithRoles.display_label }}" matTooltipPosition="above">
            {{ spaceWithRoles.displayLabel || spaceWithRoles.display_label }}
        </span>
    </div>
    <div class="location-roles">
        <mat-chip-list [attr.data-automation]="(spaceWithRoles.displayLabel || spaceWithRoles.display_label) + '_role'">
            <ng-container *ngFor="let role of spaceWithRoles.newRoles">
                <app-role-chip
                    [userId]="userId"
                    [role]="role"
                    [spaceId]="spaceWithRoles.id"
                    [newRole]="true"
                    [saving]="userService.savingUser$ | async"
                    [status]="businessRolesService.errorRolesInclude(role.businessRole, spaceWithRoles.id) ? 'FAILED' :
                        getSpaceRoleStatus(
                            businessRolesService.submittedRolesStatus$ | async,
                            role.name,
                            spaceWithRoles.id,
                            spaceWithRoles.spaceType
                        )"
                    [isEdit]="isEditMode"
                    (removeRoleEvent)="removeNewRole($event)"
                ></app-role-chip>
            </ng-container>

            <ng-container *ngFor="let role of spaceWithRoles.activeRoles">
                <app-role-chip
                    [userId]="userId"
                    [role]="role"
                    [spaceId]="spaceWithRoles.id"
                    [newRole]="false"
                    [toDelete]="spaceWithRoles.rolesToDelete.includes(role.name) || businessRolesService.errorRolesInclude(role.businessRole, spaceWithRoles.id)"
                    [status]="businessRolesService.errorRolesInclude(role.businessRole, spaceWithRoles.id) ? 'FAILED' :
                        getSpaceRoleStatus(
                            businessRolesService.submittedRolesStatus$ | async,
                            role.name,
                            spaceWithRoles.id,
                            spaceWithRoles.spaceType
                        )"
                    [saving]="userService.savingUser$ | async"
                    [isEdit]="isEditMode"
                    (removeRoleEvent)="removeCurrentRole($event)"
                    data-automation="active_location_roles_button"
                ></app-role-chip>
            </ng-container>
        </mat-chip-list>

        <ng-container *ngIf="hiddenButtons">
            <button class="x-btn-secondary roles-btn" data-automation="active_location_hidden_roles_button">
                <span> ...+{{ hiddenButtons }} </span>
            </button>
        </ng-container>
    </div>
    <div *ngIf="isEditMode && !(userService.savingUser$ | async) && showDelete && !toDelete" class="delete-location">
        <button
            (click)="removeLocation()"
            mat-icon-button
            color="primary"
            title="{{ 'user.edit.tooltip.delete_all_roles' | translate }}"
            [attr.data-automation]="'remove_entity_' + spaceWithRoles.id"
        >
            <mat-icon color="primary">delete_outline</mat-icon>
        </button>
    </div>
    <div *ngIf="toDelete" class="delete-location">
        <button
            (click)="unRemoveLocation()"
            mat-icon-button
            color="primary"
            [attr.data-automation]="'unRemove_entity_' + spaceWithRoles.id"
        >
            <mat-icon color="primary">add</mat-icon>
        </button>
    </div>
</div>
