import { Organization, SpaceGroup } from "./organization-space-group.model";
import { SiteItem } from "./site.model";

export class BusinessRole {
  constructor(
    public displayLabel = '',
    public displayLabels = {},
    public description = '',
    public descriptions = {},
    public defaultLocale = '',
    public name = '',
    public assignedAt = [],
    public businessRole = '',
  ) {}
}

export interface Entity {
  displayLabels?: object;
  displayLabel: string;
  id: string;
  spaceType: string;
}

export interface UserRole {
  selected: boolean;
  businessRole: string;
  displayLabel: string;
  display_label?: string;
  entityIds: (SiteItem | SpaceGroup | Organization)[];
}

export interface SpaceRole {
  displayLabel: string;
  display_label?: string;
  id: string;
  spaceType: string;
  space_type?: string;
  activeRoles: UserRole[];
  newRoles?: UserRole[];
  rolesToDelete?: UserRole[];
  drType?: string;
}

export enum RoleEntity {
  SpaceEntity = 'SpaceEntity',
  DrEntity = 'DREntity',
  FlexibleAsset = 'FlexibleAsset',
}

export const DR_ROLES_TO_SUBSCRIPTIONS = [
  'DR_EMAIL_NOTIFICATIONS_USER',
  'DR_VOICE_NOTIFICATIONS_USER',
  'DR_SMS_NOTIFICATIONS_USER',
  'DR_GENERAL_USER',
];

export const DR_NOTIFICATION_ROLES = [
  'DR_EMAIL_NOTIFICATIONS_USER',
  'DR_VOICE_NOTIFICATIONS_USER',
  'DR_SMS_NOTIFICATIONS_USER'
];