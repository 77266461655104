import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { Locale } from '../model/locale.model';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LocalesService {
  readonly locales$: BehaviorSubject<Locale[]> = new BehaviorSubject<Locale[]>([]);

  constructor(private apiService: ApiService) {
    this.getLocales();
  }

  async getLocales() {
    const locales = await this.apiService.get('locales');
    this.locales$.next(convertKeys.toCamel(locales));
    return locales;
  }

  checkForLocale(locale_key: string, locales: Locale[]) {
    return locales.find(locale => locale.localeName === locale_key);
  }
}
