import { Injectable } from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SidenavService {
  private sidenav: MatSidenav;
  private sidenavOpenStatusSubject = new BehaviorSubject<boolean>(false);
  public sidenavOpenStatus$: Observable<boolean>;
  public hideSideNav$ = new BehaviorSubject<boolean>(false);

  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
    if (sidenav) {
      this.sidenavOpenStatus$ = sidenav.openedChange.asObservable();
    }
  }

  public open() {
    return this.sidenav.open();
  }

  public close() {
    return this.sidenav.close();
  }

  public closeIfMobile() {
    if (this.sidenav.mode === 'over') {
      return this.sidenav.close();
    }
  }

  public getMode(): MatDrawerMode {
    return this.sidenav.mode;
  }

  public toggle(): void {
    this.sidenav.toggle();
  }

  public hideSideNav(flag) {
    this.hideSideNav$.next(flag);
  }
}
