<h4 class="card-title" data-automation="subscriptions_title">
    {{ 'user.subscriptions' | translate }}
</h4>
<h6 class="card-subtitle" data-automation="subscriptions_subtitle">
  {{ 'user.subscriptions.subtitle' | translate }}
</h6>
<mat-toolbar *ngIf="isEditMode && (!hasAnyMobilePhoneField||(!hasAnyMobilePhoneField&&!hasAnyPhoneField))" color="warn" class="warning-bar">
    <mat-icon>warning</mat-icon>
    <span *ngIf="(!hasAnyMobilePhoneField&&!hasAnyPhoneField);else text_only" class="warning-text">
      {{ 'user.subscriptions.warning' | translate }}
    </span>
    <ng-template #text_only class="warning-text">
        {{ 'user.subscriptions.text_warning' | translate }}
    </ng-template>
</mat-toolbar>
<div class="assign-sub"></div>
<div class="spinner-container" *ngIf="loading || loadingLocationsAndRoles; else finishedLoading">
    <mat-spinner mode="indeterminate" diameter="40"></mat-spinner>
</div>
<ng-template #finishedLoading>
  <div class="subscriptions-main-container">
    <div class="table-wrapper">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 sub-table">
            <!-- Site Search Column -->
            <ng-container matColumnDef="site">
                <th mat-header-cell class="search-th" *matHeaderCellDef>
                  <mat-checkbox class='select-all'
                                [disabled]='!isEditMode'
                                [checked]="allComplete"
                                [color]="'primary'"
                                [indeterminate]="someComplete()"
                                (change)="setAll($event.checked)">
                    <span *ngIf="allComplete">{{ 'user.table.all_sites' | translate }}</span>
                    <span *ngIf="someComplete()">{{ 'user.table.some_sites' | translate }}</span>
                    <span *ngIf="!allComplete && !someComplete()">{{ 'user.table.no_sites' | translate }}</span>
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox [(ngModel)]="element.completed"
                                [disabled]='!isEditMode'
                                [color]="element.color"
                                (ngModelChange)="updateAllComplete()"
                                (change)="toggleSiteCompleted(element)">
                    {{ element.name }}
                  </mat-checkbox>
                </td>
            </ng-container>

            <!-- Text Column -->
            <ng-container matColumnDef="text">
                <th mat-header-cell class="title centered method" *matHeaderCellDef>
                  <mat-label>{{ 'user.table.sms' | translate }}</mat-label>
                  <mat-form-field appearance="none" style="height:0.5em;" floatLabel="always">
                    <mat-select class='align-arrow-right' #matRefText (selectionChange)="changeBulkSubAction('sms', $event)" [disabled]="!isSelectEnabled()">
                      <mat-option *ngFor="let action of bulkSubActionsText" [value]="action.value">
                        {{ action.viewValue }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <mat-checkbox [attr.data-automation]="'text-cb-' + element.tag" class="checkbox" [(ngModel)]="element.sms" [disabled]="!isEditMode || !hasAnyMobilePhoneField" (change)="changeSub('sms', $event)"> </mat-checkbox>
                </td>
            </ng-container>

            <!-- Voice Column -->
            <ng-container matColumnDef="voice">
                <th mat-header-cell class="title centered method" *matHeaderCellDef>
                  <mat-label>{{ 'user.table.voice' | translate }}</mat-label>
                  <mat-form-field appearance="none" style="height:0.5em;" floatLabel="always">
                    <mat-select class='align-arrow-right' #matRefVoice (selectionChange)="changeBulkSubAction('voice', $event)" [disabled]="!isSelectEnabled()">
                      <mat-option *ngFor="let action of bulkSubActionsVoice" [value]="action.value">
                        {{ action.viewValue }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <mat-checkbox [attr.data-automation]="'voice-cb-' + element.tag"  class="checkbox" [(ngModel)]="element.voice" [disabled]="!isEditMode || !(hasAnyMobilePhoneField||hasAnyPhoneField)" (change)="changeSub('voice', $event)"> </mat-checkbox>
                </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
                <th mat-header-cell class="title centered method" *matHeaderCellDef>
                  <mat-label>{{ 'user.table.email' | translate }}</mat-label>
                  <mat-form-field appearance="none" style="height:0.5em;" floatLabel="always">
                      <mat-select class='align-arrow-right' matInput #matRefEmail (selectionChange)="changeBulkSubAction('email', $event)" [disabled]="!isSelectEnabled()">
                        <mat-option *ngFor="let action of bulkSubActionsEmail" [value]="action.value">
                          {{ action.viewValue }}
                        </mat-option>
                      </mat-select>
                  </mat-form-field>
                </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <mat-checkbox [attr.data-automation]="'email-cb-' + element.tag" class="checkbox" [(ngModel)]="element.email" [disabled]="!isEditMode||!hasEmailField" (change)="changeSub('email', $event)"> </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="header-row-first-group">
                <th mat-header-cell *matHeaderCellDef class="title centered method th-site" [attr.colspan]="1">
                  <mat-form-field class="search-input" appearance="outline" floatLabel="always">
                    <input
                      matInput
                      type="text"
                      class="search-text"
                      data-automation="search"
                      (input)="applyFilter($event.target.value)"
                      placeholder="{{ 'user.table.search_site' | translate }}"
                    />
                  </mat-form-field>
                </th>
            </ng-container>

            <ng-container matColumnDef="header-row-second-group">
                <th mat-header-cell *matHeaderCellDef class="title centered method dispatch" [attr.colspan]="3">
                  <mat-label>{{ 'user.table.dispatch' | translate }}</mat-label>
                  <mat-form-field appearance="none" style="height:0.5em;" floatLabel="always">
                    <mat-select class='align-arrow-right-dispatch' #matRefDispatch (selectionChange)="changeBulkSubActions($event)" [disabled]="!isSelectEnabled()">
                      <mat-option *ngFor="let action of bulkSubActions" [value]="action.value">
                        {{ action.viewValue }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </th>
            </ng-container>

            <tr
                mat-header-row
                *matHeaderRowDef="['header-row-first-group', 'header-row-second-group']; sticky: true"
            ></tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div *ngIf="!dataSource?.data.length && !loading" class="no-results centered">
            {{ 'user.subscriptions.no_results' | translate }}
        </div>

        
    </div>
  </div>
  <mat-paginator
            *ngIf="totalRecords > 4"
            [pageSizeOptions]="[10]"
            [length]="totalRecords"
            (page)="pageEvent = $event; onPaginateChange($event)"
            [pageSize]="pageSize"
            showFirstLastButtons
        ></mat-paginator>
</ng-template>
