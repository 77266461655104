export class Status {
  constructor(public id = '1', public displayLabel = 'Inactive', public code = 'INACTIVE') {}
}

export interface StatusAction {
  endpoint: StatusUpdateActions;
  displayLabel: string;
}

export type StatusUpdateActions =
  | 'WELCOME'
  | 'ACTIVATE'
  | 'RESEND_ACTIVATION'
  | 'DEACTIVATE'
  | 'TERMINATE'
  | 'FORGOT_PASSWORD';

const DEACTIVATE: StatusAction = { endpoint: 'DEACTIVATE', displayLabel: 'deactivate' };
const ACTIVATE: StatusAction = { endpoint: 'ACTIVATE', displayLabel: 'activate' };
const WELCOME: StatusAction = { endpoint: 'WELCOME', displayLabel: 'activate' };
const TERMINATE: StatusAction = { endpoint: 'TERMINATE', displayLabel: 'terminate' };
const REACTIVATE: StatusAction = { endpoint: 'ACTIVATE', displayLabel: 'reactivate' };
const RESEND_ACTIVATION: StatusAction = { endpoint: 'RESEND_ACTIVATION', displayLabel: 'resend_activation' };

export const StatusToActionsList = {
  Active: [DEACTIVATE],
  Inactive: [ACTIVATE, DEACTIVATE],
  Deactivated: [REACTIVATE, TERMINATE],
  Unverified: [RESEND_ACTIVATION, DEACTIVATE],
  Pending: [WELCOME, DEACTIVATE],
};
