import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { Brand, BRANDED_USERS } from '../model/brand.model';

@Injectable()
export class BrandsService {
  readonly brands$: BehaviorSubject<Brand[]> = new BehaviorSubject<Brand[]>([]);

  constructor(private apiService: ApiService) {
    this.getBrands();
  }

  async getBrands() {
    const brands = await this.apiService.get('brands');
    const convertedBrands: Brand[] = convertKeys.toCamel(brands);
    this.brands$.next(convertedBrands);
    return convertedBrands;
  }

  getFilteredBrands(filter: string, brands: Brand[]): Brand[] {
    return BRANDED_USERS.includes(filter) ? [brands.find(item => item.name === filter)] : brands;
  }

  filterBrandsByUserType(userType: string, brands: Brand[]): Brand[] {
    return userType === 'CONSUMERSENERGY'
      ? [brands.find(item => item.name === 'CONSUMERSENERGY')]
      : brands.filter(item => item.name !== 'CONSUMERSENERGY');
  }
}
