import { Injectable } from '@angular/core';
import { ContactSubscription, ContactSubscriptionPost } from '../model/user.model';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import * as convertKeys from 'convert-keys';

@Injectable()
export class ContactSubscriptionService {
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public contactSubscriptions$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  constructor(private apiService: ApiService) {}

  async updateContactSubscriptions(userId: string, subscriptions: ContactSubscription[], deleted_subscriptions?: any) {
    try {
      const response = await this.apiService.put(`contacts/subscriptions/${userId}`, {
        subscriptions,
        deleted_subscriptions,
      });
      return response;
    } catch (err) {
      console.log('Could not update subscription for user', err);
      throw err;
    }
  }

  async postContactSubscription(contactId: string, subscription: ContactSubscriptionPost) {
    try {
      const response = await this.apiService.post(`contacts/${contactId}/subscriptions`, 
        subscription
      );
      return response;
    } catch (err) {
      console.log('Could not update subscription for user', err);
      throw err;
    }
  }

  async deleteContactSubscription(contactId: string, subscriptionId: string) {
    try {
      const response = await this.apiService.delete(`contacts/${contactId}/subscriptions/${subscriptionId}`);
      return response;
    } catch (err) {
      console.log('Could not delete subscription for user', err);
      throw err;
    }
  }

  async getContactSubscriptions(userId: string) {
    try {
      this.loading$.next(true);
      const response = await this.apiService.get(`contacts/subscriptions/${userId}`);
      this.contactSubscriptions$.next(convertKeys.toCamel<[]>(response));
    } catch (err) {
      console.log('Could not retrieve subscriptions', err);
      throw err;
    } finally {
      this.loading$.next(false);
    }
  }
}
