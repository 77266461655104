<div class="spinner-container" *ngIf="loadingLocationsAndRoles; else finishLoadingLocationAndRoles">
    <mat-spinner mode="indeterminate" diameter="40"></mat-spinner>
</div>

<ng-template #finishLoadingLocationAndRoles>
    <div
        *ngIf="spacesWithRoles && !spacesWithRoles.length && !newSpacesWithRoles.length; else locationRoles"
        class="no-locations"
    >
        <mat-icon inline color="primary" class="no-roles-icon">warning</mat-icon>
        <h2 data-automation="no_locations_configured">
            {{ 'user.edit.active_roles.no_user_roles' | translate }}
        </h2>
    </div>
    <ng-template #locationRoles>
        <div class="space-role-table">
            <div class="space-role-table-header">
                <div class="location-small-cell">{{ 'user.edit.active_roles.entity' | translate }}</div>
                <div class="location-medium-cell">{{ 'user.edit.active_roles.name' | translate }}</div>
                <div>{{ 'user.edit.table.heading.roles' | translate }}</div>
                <div class="location-small-cell"></div>
            </div>
            <app-active-location
                class="space-role-row"
                *ngFor="let newSpaceWithRoles of newSpacesWithRoles"
                [userId]="userId"
                [sync]="ecrmSync"
                [contactId]="contactId"
                [spaceWithRoles]="newSpaceWithRoles"
                [mode]="mode"
                (onRoleRemoval)="onRoleRemoved($event)"
                (removeLocationEvent)="removeNewLocation($event)"
            ></app-active-location>
            <app-active-location
                class="space-role-row"
                *ngFor="let spaceWithRoles of spacesWithRoles"
                [userId]="userId"
                [sync]="ecrmSync"
                [contactId]="contactId"
                [allActiveRoles]="spacesWithRoles"
                [spaceWithRoles]="spaceWithRoles"
                (removeLocationEvent)="removeLocation($event)"
                [mode]="mode"
                (onRoleRemoval)="onRoleRemoved($event)"
                (unRemoveRoleEvent)="unRemoveRole($event)"
            ></app-active-location>
        </div>
    </ng-template>
</ng-template>
<div class="button-div" *ngIf="mode==EDIT">
    <button
                class="icon-btn x-btn-primary"
                data-automation="rolesSubmit"
                [disabled]="!changed"
                (click)="openSubmitRolesDialog()"
            >
        <mat-icon *ngIf="!false">done</mat-icon>
        <div *ngIf="false" class="btn-spinner">
            <mat-spinner diameter="15"></mat-spinner>
        </div>
        <span class="btn-text">{{ 'user.edit.save_roles' | translate }}</span>
    </button>
    <button class="icon-btn x-btn-secondary" (click)="resetRoles()" data-automation="rolesReset">
        <span class="btn-text"> {{ 'user.button.reset' | translate }}</span>
    </button>
</div>