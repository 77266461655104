import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | phone_number
 * Example:
 *   {{ '5555555555' | phone_numer }}
 *   formats to: 555-555-5555
 */
@Pipe({ name: 'phone_number' })
export class PhoneNumberPipe implements PipeTransform {
  transform(phone_number: string): string {
    if (!phone_number) {
      return '';
    }

    phone_number = phone_number.replace(/[^0-9]+/g, '');

    if (phone_number.length < 4) {
      return `${phone_number}`;
    }

    if (phone_number.length < 7) {
      return `${phone_number.slice(0, 3)}-${phone_number.slice(3, 6)}`;
    }

    return `${phone_number.slice(0, 3)}-${phone_number.slice(3, 6)}-${phone_number.slice(6, 10)}`;
  }
}
