import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';

@Injectable()
export class DrGroupService {
  groups$: BehaviorSubject<object>;

  constructor(private apiService: ApiService) {
    this.groups$ = new BehaviorSubject<any>({});
  }

  async getDrGroups() {
    try {
      const groups = await this.apiService.get(`dr-groups`);
      const convertedGroups: any = convertKeys.toCamel(groups.data);
      this.groups$.next(convertedGroups);
      return convertedGroups;
    } catch (err) {
      console.log('Could not load groups.', err);
    }
  }
}
