import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import * as convertKeys from 'convert-keys';
import { Application } from '../model/application.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ApplicationsService {
  applications$: BehaviorSubject<Application[]> = new BehaviorSubject<Application[]>([]);
  private userId: string;

  constructor(private apiService: ApiService, private usersService: UserService) {
    this.usersService.loggedInUser$.subscribe(user => {
      if (user?.userId) {
        if (this.userId !== user.userId) {
          this.userId = user.userId;
          this.getApplications();
        }
      }
    });
  }
  async getApplications() {
    if (this.userId) {
      const appsResponse = await this.apiService.get(`${this.userId}/applications`);
      if (this.applications$ && Array.isArray(this.applications$)) {
        while (this.applications$.length > 0) {
          this.applications$.pop();
        }
      }
      if (appsResponse.data) {
        const appsList = appsResponse.data.map((app: any) => {
          const appObject: any = convertKeys.toCamel(app);
          delete appObject.label;
          return {
            ...appObject,
            descriptions: app.descriptions || {},
            displayLabels: app.display_labels || {},
            displayLabel: app.label,
          };
        });
        this.applications$.next(appsList);
        return appsList;
      }
    }
  }

  async getApplicationsByUser(userId: string) {
    if (userId) {
      const appsResponse = await this.apiService.get(`${userId}/home-applications`);
      if (this.applications$ && Array.isArray(this.applications$)) {
        while (this.applications$.length > 0) {
          this.applications$.pop();
        }
      }
      if (appsResponse) {
        
        const appsList = Object.values(appsResponse).map((app: any) => {
          const appObject: any = convertKeys.toCamel(app);
          delete appObject.label;
          return {
            ...appObject,
            descriptions: app.descriptions || {},
            displayLabels: app.display_labels || {},
            displayLabel: app.display_label,
          };
        });
        this.applications$.next(appsList);
        // console.log('appsList', appsList);
        return appsList;
      }
    }
  }
}
