import { IconDefinition } from 'ngx-tree-selector';
export const iconDefinitions: IconDefinition = {
  Admingroup: {
    name: 'people_outline',
    type: '',
    namespace: '',
  },
  AdminGroup: {
    name: 'people_outline',
    type: '',
    namespace: '',
  },
  Organization: {
    name: 'language',
    type: '',
    namespace: '',
  },
  Spacegroup: {
    name: 'folder_open',
    type: '',
    namespace: '',
  },
  Metergroup: {
    name: 'folder_open',
    type: '',
    namespace: '',
  },
  Site: {
    name: 'apartment',
    type: '',
    namespace: '',
  },
  Meter: {
    name: 'offline_bolt',
    type: '',
    namespace: '',
  },
  Operator: {
    name: 'person_outline',
    type: '',
    namespace: '',
  },
  Program: {
    name: 'list_alt',
    type: '',
    namespace: '',
  },
  Product: {
    name: 'view_list',
    type: '',
    namespace: '',
  },
  Portfolio: {
    name: 'folder_open',
    type: '',
    namespace: '',
  },
  DRGroup: {
    name: 'people_outline',
    type: '',
    namespace: '',
  },
};
