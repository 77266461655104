<ng-container *ngIf="dialogState === 'DEFAULT'" class="default-container">
    <div mat-dialog-content data-automation="delete-role-dialog">
        <ng-container data-automation="dialogText">
            <span class="dialog-title" data-automation="dialogTitle">{{
                'user.dialog.submit_roles.title' | translate
            }}</span>
            <div class="title-separator"></div>
            <ng-container *ngIf="warning">
                <div class="custom-alert custom-alert-danger" role="alert">
                    {{warning | translate }}
                </div>
            </ng-container>
            <div class="dialog-text-container">
                <span data-automation="rolecount" class="chip-entity dialog-item">{{ "user.edit.roles_added" | translate }} {{data.totalAddedRoles}}</span><br/>
                <span data-automation="rolecount" class="dialog-item chip-entity">{{ "user.edit.roles_deleted" | translate }} {{totalDeletedRoles}}</span>
            </div>
        </ng-container>
    </div>
    <div mat-dialog-actions>
        <button
            class="x-btn-secondary dialog-secondary"
            aria-label="Cancel"
            (click)="onNoClick()"
            data-automation="dialogCancel"
        >
            <span>{{ 'user.button.cancel' | translate }}</span>
        </button>

        <button
            class="x-btn-primary dialog-primary"
            aria-label="Update"
            (click)="handleUpdate()"
            data-automation="dialogConfirm"
        >
            <span>{{ 'user.button.confirm' | translate }}</span>
        </button>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'UPDATING'">
    <div mat-dialog-content>
        <div class="dialog-spinner">
            <div class="spinner-container">
                <mat-spinner diameter="24"></mat-spinner>
            </div>
            <span>
                <ng-container *ngIf="data.totalAddedRoles > 0">
                    <span data-automation="dialogTextDeleting">{{ 'user.dialog.submit_roles.submitting' | translate }}</span>
                    <span>{{ added }} / {{ data.totalAddedRoles }}</span>
                </ng-container>
                <ng-container *ngIf="totalDeletedRoles > 0 && data.totalAddedRoles > 0">
                    <br/>
                </ng-container>
                <ng-container *ngIf="totalDeletedRoles > 0">
                    <span data-automation="dialogTextDeleting">{{ 'user.dialog.delete_roles.deleting_roles' | translate }}</span>
                    <span>{{ deleted }} / {{ totalDeletedRoles }}</span>
                </ng-container>
            </span>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'SUCCESS'">
    <div mat-dialog-content>
        <div class="dialog-result">
            <mat-icon class="icon-success">task_alt</mat-icon>
            <ng-container >
                <span data-automation="dialogTextSuccess">{{ 'user.dialog.submit_roles.success' | translate }}</span>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'ERROR'">
    <div mat-dialog-content>
        <div class="dialog-result">
            <mat-icon class="icon-failed">warning_amber</mat-icon>
            <div class="dialog-text-container">
                <span data-automation="rolecount" class="chip-entity dialog-item">{{ 'user.dialog.submit_roles.operations' | translate }} {{data.totalAddedRoles + totalDeletedRoles - errors}}</span><br/>
                <span data-automation="rolecount" class="dialog-item chip-entity">{{ 'user.dialog.submit_roles.errors' | translate }} {{errors}}</span>
            </div>
        </div>
    </div>
</ng-container>
