<mat-list class="float-right">
    <mat-list-item outline color="primary" class="status-item">
        <div class="status-icon" [ngClass]="'status-icon-' + status.toLowerCase()"></div>
        <span class="status-label" data-automation="status-bar-title">{{ getStatusForUser(status) }}</span>
        <span class="separator-spacer"></span>
        <ng-container *ngIf="isActionableStatus() && canUpdate">
            <mat-icon
                class="status-change-icon"
                data-automation="status-bar-menu"
                mat-button
                [matMenuTriggerFor]="belowMenu"
                >more_horiz</mat-icon
            >
            <mat-menu #belowMenu="matMenu" class="customize-mat-menu" yPosition="below" xPosition="before">
                <ng-container *ngFor="let action of statusToActionsList[status]; let i = index">
                    <mat-divider></mat-divider>
                    <span
                        class="change-status-menu-item"
                        [ngClass]="action.displayLabel"
                        [attr.data-automation]="action.endpoint === 'TERMINATE' ? 'terminate-btn' : 'change-status-btn'"
                        mat-menu-item
                        (click)="openUpdateStatusDialog(action.endpoint)"
                        >{{ getLocalizedButtonLabel(action.displayLabel) }}
                    </span>
                </ng-container>
                <mat-divider></mat-divider>
            </mat-menu>
        </ng-container>
    </mat-list-item>
</mat-list>
