import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as convertKeys from 'convert-keys';
import {BehaviorSubject, Observable} from 'rxjs';
import { Status } from '../model/status.model';
import { ApiService } from './api.service';
import { StatusUpdateActions } from '../model/status.model';
import { UserService } from './user.service';
import {User, UserStatus} from '../model/user.model';
import {map, mergeMap} from 'rxjs/operators';

@Injectable()
export class UserStatusService {
  statuses: Status[];
  statuses$ = new BehaviorSubject<Status[]>([]);
  userStatus$: Observable<UserStatus>;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private translateService: TranslateService
  ) {
    this.statuses = [];
    this.retrieveStatuses();
    this.userStatus$ = this.userService.user$.pipe(map((user: User) => user.status));
  }

  getTranslatedStatus(status: string) {
    if (status && this.statuses.length > 0) {
      const userStatus = status.toLowerCase();
      return (
        this.statuses.find(status => {
          return userStatus === status.code.toLowerCase();
        }).displayLabel || ''
      );
    }

    return this.translateService.instant('user.list.status.unknown');
  }

  async retrieveStatuses() {
    let { data: statusesObject } = await this.apiService.get('statuses');

    statusesObject = convertKeys.toCamel<Status[]>(statusesObject);

    if (this.statuses && Array.isArray(this.statuses)) {
      this.statuses.length = 0; //clear statuses array
    }

    if (statusesObject) {
      for (const [code, o] of Object.entries<any>(statusesObject)) {
        this.statuses.push({
          code,
          ...o,
        });
      }
    }
    this.statuses$.next(this.statuses);
  }

  async updateUserStatus(userID: string, action: StatusUpdateActions) {
    try {
      const userUpdated = await this.apiService.put(`users/${userID}/status/${action}`, {});
      return userUpdated;
    } catch (err) {
      console.log(`Could not Update User`, err);
      throw err;
    }
  }
}
