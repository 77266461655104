import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class CountriesService {
  countries$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(private apiService: ApiService) {
    this.getCountries();
  }

  getCountryCode(code: string) {
    const found = this.countries$.value.find(country => {
      return country.id === code;
    });

    if (!found) {
      return undefined;
    }

    return found.callingCodes[0];
  }

  getCountryCodeByName(name: string) {
    const found = this.countries$.value.find(country => {
      return country.display_label === name || country.name === name;
    });

    if (found) {
      return found.callingCodes[0];
    }

    return found;
  }

  getCountryNameFromId(id: string): string {
    const country = this.countries$.value.find(country => {
      return country.id === id;
    });
    return country.name || '';
  }

  async getCountries() {
    try {
      const countries = await this.apiService.get('countries');
      const countriesArr = Object.values(countries);

      this.countries$.next(countriesArr);
    } catch (err) {
      console.log('Could not load reporting intervals.', err);
    }
  }

  async getPhoneNumber(phone: string, country: string, extension = '', phoneType = 'WORK') {
    // This is spotty at best.  Be warned
    let countryCode = this.getCountryCodeByName(country);

    if (countryCode) {
      // replace everything except numbers
      const formattedPhone = phone.replace(/[^\d]/g, '');
      //country codes are max 3 characters lets limit the search to that.
      const foundCountryCodeInNum = formattedPhone.slice(0, 3).indexOf(countryCode) !== -1;
      let phoneNumber = formattedPhone;

      if (foundCountryCodeInNum) {
        phoneNumber = formattedPhone.split(countryCode)[1];
      } else {
        // Didn't find the country code in the phone number... let's see if we can determine if it is domestic
        if (country && !['united states', 'canada'].includes(country.toLowerCase())) {
          return null;
        } else {
          countryCode = '1';
        }
      }

      const returnPhone: any = {
        primary: phoneType === 'WORK',
        phone_type: phoneType,
        phone_number: phoneNumber,
        country_code: countryCode,
      };

      if (extension) {
        returnPhone.extension = extension;
      }
      return returnPhone;
    } else {
      return null;
    }
  }
}
