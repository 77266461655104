<div class="app-user-view content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-spinner *ngIf="loadingUserDetails" matSuffix diameter="30" color="warn"></mat-spinner>
                    <mat-icon *ngIf="!loadingUserDetails">person</mat-icon>
                </div>
                <h1 data-automation="view-title">
                    <ng-container *ngIf="!loadingUserDetails">
                        {{ user.fullName }}
                    </ng-container>
                    <ng-container *ngIf="loadingUserDetails">
                        {{ 'user.loading_user' | translate }}
                    </ng-container>
                </h1>
                <p *ngIf="user" class="subtitle" data-automation="view-subtitle">
                    <span class="status-text" data-automation="status">{{ user.email }}</span>
                </p>
            </div>
        </div>

        <div class="right-column">
            <button
                class="icon-btn x-btn-primary"
                data-automation="userEdit"
                [disabled]="!enableEditBtn()"
                (click)="handleEdit()"
            >
                <mat-icon>edit</mat-icon>
                <span class="btn-text">{{ 'user.button.edit' | translate }}</span>
            </button>
        </div>
    </header>

    <app-user-status-bar [status]="user.status" [userId]="userId" [selectedOrg]="orgId$ | async"></app-user-status-bar>

    <form #view="ngForm" class="user-form">
        <!--- VIEW --->
        <ng-container *ngIf="user">
            <div data-automation="userLoaded" *ngIf="!loadingUserDetails"></div>
            <div class="mrgn-top"></div>

            <div>
                <div class="form-group">
                    <!--- First name --->
                    <div class="form-entry" *ngIf="!doesFullNameContainNames">
                        <mat-label class="form-entry-column info-title">{{ 'user.first_name' | translate }}:</mat-label>
                        <mat-label class="info-value" data-automation="first_name">
                            {{ user.first_names[user.locale] }}
                        </mat-label>
                    </div>

                    <!--- Last name --->
                    <div class="form-entry" *ngIf="!doesFullNameContainNames">
                        <mat-label class="form-entry-column info-title">{{ 'user.last_name' | translate }}:</mat-label>
                        <mat-label class="info-value" data-automation="last_name">
                            {{ user.last_names[user.locale] }}
                        </mat-label>
                    </div>

                    <!--- Organization --->
                    <div class="form-entry">
                        <mat-label class="info-title">{{ 'user.organization' | translate }}:</mat-label>
                        <mat-label class="info-value" data-automation="organization">
                            {{ user ? user.organization_name : '-' }}
                        </mat-label>
                    </div>

                    <!--- Username --->
                    <div class="form-entry">
                        <mat-label class="form-entry-column info-title">{{ 'user.user_name' | translate }}:</mat-label>
                        <mat-label class="info-value" data-automation="username">
                            {{ user.username }}
                        </mat-label>
                    </div>

                    <!--- Title --->
                    <div class="form-entry">
                        <mat-label class="info-title">{{ 'user.title' | translate }}:</mat-label>
                        <mat-label class="info-value" data-automation="business_role">
                            {{ user && user.title ? user.title : '-' }}
                        </mat-label>
                    </div>

                    <!--- User Type --->
                    <div class="form-entry">
                        <mat-label class="info-title">{{ 'user.user_type' | translate }}:</mat-label>
                        <mat-label class="info-value" data-automation="brand">
                            {{ user.userType }}
                        </mat-label>
                    </div>

                    <!--- Locale --->
                    <div class="form-entry">
                        <mat-label class="info-title">{{ 'user.locale' | translate }}:</mat-label>
                        <mat-label class="info-value" data-automation="locale">
                            {{ getLocaleForUser().displayLabel }}
                        </mat-label>
                    </div>

                    <!--- User Brand --->
                    <div class="form-entry">
                        <mat-label class="info-title">{{ 'user.brand' | translate }}:</mat-label>
                        <mat-label class="info-value" data-automation="brand">
                            {{ user.brand ? getBrandForUser() : '-' }}
                        </mat-label>
                    </div>

                    <!--- User Class --->
                    <div class="form-entry" >
                        <mat-label class="info-title">{{ 'user.system_user' | translate }}:</mat-label>
                        <mat-label class="info-value" data-automation="system_user">
                            {{ user.userClass === 'SYSTEM'}}
                        </mat-label>
                    </div>
                </div>
                <div class="button-container">
                    <div class="right-button">
                        <button class="icon-btn x-btn-secondary squared" (click)="collapseAll()">
                            <mat-icon>expand_less</mat-icon>
                            <span class="btn-text">{{ 'user.collapse_all' | translate }}</span>
                        </button>
                    </div>
                    <div class="right-button">
                        <button class="icon-btn x-btn-secondary squared" (click)="expandAll()">
                            <mat-icon>expand_more</mat-icon>
                            <span class="btn-text">{{ 'user.expand_all' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>

            <mat-expansion-panel
                togglePosition="before"
                data-automation="contact_information_panel"
                [expanded]="collapseArray[0]"
                (opened)="expansionOpened(0)"
                (closed)="expansionClosed(0)"
            >
                <mat-expansion-panel-header
                    class="panel-override"
                    collapsedHeight="54px"
                    expandedHeight="54px"
                    data-automation="contact_information_header"
                >
                    <mat-panel-title>
                        <h4 class="card-title" data-automation="contact_information">
                            {{ 'user.contact_information' | translate }}
                        </h4>
                    </mat-panel-title>
                    <mat-panel-description class="contact_information_subtitle"> </mat-panel-description>
                </mat-expansion-panel-header>

                <!--- User Preferences --->
                <!-- <h4 class="form-header">{{ 'user.preferences' | translate }}</h4> -->
                <div class="form-group">
                    <!--- Email --->
                    <div class="form-entry">
                        <mat-label class="info-title">{{ 'user.email' | translate }}:</mat-label>
                        <mat-label class="info-value" data-automation="email">
                            {{ user.email }}
                        </mat-label>
                    </div>

                    <!--- Phone Number --->
                    <div class="form-entry label-info">
                        <div class="wrapper">
                            <div class="info-box">
                                <mat-label class="flex-info-title"> {{ 'user.phone_number' | translate }}: </mat-label>
                                <mat-icon
                                    svgIcon="general-info"
                                    data-automation="phoneIcon"
                                    matSuffix
                                    class="tooltip-icon"
                                    matTooltip="{{ 'user.tooltip.phone_number' | translate }}"
                                ></mat-icon>
                            </div>
                        </div>
                        <div class="form-entry-column" data-automation="phone_number">
                            <ng-container *ngIf="hasPhone()">
                                <mat-label *ngFor="let phone of user.phones">
                                    <ng-container *ngIf="phone.primary && phone.phone_type != 'MOBILE'">
                                        <span *ngIf="phone.country_code">+{{ phone.country_code }}</span>
                                        {{ phone.phone_number | phone_number }}
                                        <span *ngIf="phone.extension">(ext {{ phone.extension }})</span>
                                    </ng-container>
                                </mat-label>
                            </ng-container>
                            <ng-container *ngIf="!hasPhone()">
                                <mat-label>-</mat-label>
                            </ng-container>
                        </div>
                    </div>

                    <!--- Mobile Phone Number --->
                    <div class="form-entry label-info">
                        <div class="wrapper">
                            <div class="info-box">
                                <mat-label class="flex-info-title">
                                    {{ 'user.mobile_phone_number' | translate }}:
                                </mat-label>
                                <mat-icon
                                    svgIcon="general-info"
                                    data-automation="mobilePhoneIcon"
                                    matSuffix
                                    class="tooltip-icon"
                                    matTooltip="{{ 'user.tooltip.mobile_phone_number' | translate }}"
                                ></mat-icon>
                            </div>
                        </div>
                        <div class="form-entry-column" data-automation="mobile_phone_number">
                            <ng-container *ngIf="hasMobile()">
                                <mat-label *ngFor="let phone of user.phones">
                                    <ng-container *ngIf="!phone.primary && phone.phone_type == 'MOBILE'">
                                        <span *ngIf="phone.country_code">+{{ phone.country_code }}</span>
                                        {{ phone.phone_number | phone_number }}
                                        <span *ngIf="phone.extension">(ext {{ phone.extension }})</span>
                                    </ng-container>
                                </mat-label>
                            </ng-container>
                            <ng-container *ngIf="!hasMobile()">
                                <mat-label>-</mat-label>
                            </ng-container>
                        </div>
                    </div>

                    <!--- primary_contact --->
                    <div class="form-entry">
                        <mat-label class="info-title">{{ 'user.primary_contact' | translate }}:</mat-label>
                        <mat-label class="info-value" data-automation="primary_contact">
                            {{ !!user.primary_contact }}
                        </mat-label>
                    </div>

                    <!--- twentyfour_seven_contact --->
                    <div class="form-entry">
                        <mat-label class="info-title">{{ 'user.24_7_contact' | translate }}:</mat-label>
                        <mat-label class="info-value" data-automation="twentyfour_seven_contact">
                            {{ !!user.twentyfour_seven_contact }}
                        </mat-label>
                    </div>
                </div>

                <div class="form-group table-container">
                    <app-subscription-table mode="view" [userId]="userId"></app-subscription-table>
                </div>
            </mat-expansion-panel>

            <ng-container *ngIf="showRoles">
                <mat-expansion-panel
                    togglePosition="before"
                    data-automation="user_roles_panel"
                    [expanded]="collapseArray[1]"
                    (opened)="expansionOpened(1)"
                    (closed)="expansionClosed(1)"
                >
                    <mat-expansion-panel-header
                        class="panel-override"
                        collapsedHeight="54px"
                        expandedHeight="54px"
                        data-automation="user_roles_header"
                    >
                        <mat-panel-title>
                            <h4 class="card-title" data-automation="user_roles">{{ 'user.user_roles' | translate }}</h4>
                        </mat-panel-title>
                        <mat-panel-description class="user_roles_subtitle"> </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="form-group">
                        <app-active-location-roles></app-active-location-roles>
                    </div>
                </mat-expansion-panel>
            </ng-container>

            <mat-expansion-panel
                togglePosition="before"
                data-automation="sync_information_panel"
                [expanded]="collapseArray[2]"
                (opened)="expansionOpened(2)"
                (closed)="expansionClosed(2)"
            >
                <mat-expansion-panel-header
                    class="panel-override"
                    collapsedHeight="54px"
                    expandedHeight="54px"
                    data-automation="sync_information_header"
                >
                    <mat-panel-title>
                        <h4 class="card-title" data-automation="sync_information">
                            {{ 'user.sync_information' | translate }}
                        </h4>
                    </mat-panel-title>
                    <mat-panel-description class="sync_information_subtitle"> </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="form-group">
                    <!-- ECRM Contact ID -->
                    <div class="form-entry">
                        <div>
                            <mat-label class="info-title">{{ 'user.ecrm_contact_id' | translate }}:</mat-label>
                        </div>
                        <div class="form-entry-column" data-automation="ecrm_contact_id">
                            <mat-label>{{ user.contact_id ? user.contact_id : '-' }}</mat-label>
                        </div>
                    </div>

                    <!-- ECRM ID -->
                    <div class="form-entry">
                        <div>
                            <mat-label class="info-title">{{ 'user.ecrm_id' | translate }}:</mat-label>
                        </div>
                        <div class="form-entry-column" data-automation="ecrm_id">
                            <mat-label>{{ user.ecrm_id ? user.ecrm_id : '-' }}</mat-label>
                        </div>
                    </div>

                    <!--- Last sync date --->
                    <div *ngIf="user.sync_to_ecrm" class="form-entry">
                        <mat-label data-automation="last_sync_label" class="form-entry-column info-title"
                            >{{ 'user.last_sync_date' | translate }}:</mat-label
                        >
                        <mat-label *ngIf="user.last_sync_date" class="info-value" data-automation="last_sync_date">
                            {{ user.last_sync_date | date : 'medium' }}
                        </mat-label>
                        <mat-label *ngIf="!user.last_sync_date" class="info-value" data-automation="last_sync_date">
                            {{ 'user.no_sync_to_ecrm' | translate }}
                        </mat-label>
                    </div>

                    <!--- Sync to ECRM --->
                    <div data-automation="sync_to_ecrm_label" class="form-entry">
                        <mat-label class="form-entry-column info-title"
                            >{{ 'user.sync_to_ecrm' | translate }}:</mat-label
                        >
                        {{ user.sync_to_ecrm ? !!user.sync_to_ecrm : '-' }}
                    </div>

                    <!-- Addresses -->
                    <div class="form-entry">
                        <div>
                            <mat-label class="info-title">{{ 'user.address' | translate }}:</mat-label>
                        </div>
                        <div class="form-entry-column" data-automation="address">
                            <ng-container *ngIf="user.addresses && user.addresses.length">
                                <ng-container *ngFor="let address of user.addresses">
                                    <mat-label>{{ user.addresses[0].address_1 }}</mat-label>
                                    <mat-label>{{ user.addresses[0].address_2 }}</mat-label>
                                    <mat-label>
                                        {{ user.addresses[0].city }}{{ user.addresses[0].city ? ', ' : '' }}
                                        {{ user.addresses[0].state_province }}
                                        {{ user.addresses[0].postal_code }}
                                    </mat-label>
                                    <br />
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!user.addresses || !user.addresses.length">
                                <mat-label>-</mat-label>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel
                togglePosition="before"
                data-automation="user_settings_panel"
                [expanded]="collapseArray[3]"
                (opened)="expansionOpened(3)"
                (closed)="expansionClosed(3)"
            >
                <mat-expansion-panel-header
                    class="panel-override"
                    collapsedHeight="54px"
                    expandedHeight="54px"
                    data-automation="user_settings_header"
                >
                    <mat-panel-title>
                        <h4 class="card-title" data-automation="user_settings">
                            {{ 'user.user_settings' | translate }}
                        </h4>
                    </mat-panel-title>
                    <mat-panel-description class="user_settings_subtitle"> </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="form-group">
                    <!--- Timezone --->
                    <div class="form-entry">
                        <mat-label class="info-title">{{ 'user.timezone' | translate }}:</mat-label>
                        <mat-label class="info-value ellipsis" data-automation="timezone">
                            {{ getTimezoneForUser().displayLabel }}
                        </mat-label>
                    </div>

                    <!--- Measurement System --->
                    <div class="form-entry">
                        <mat-label class="info-title">{{ 'user.measurement' | translate }}:</mat-label>
                        <mat-label class="info-value" data-automation="measurement_system">
                            {{ user.unit_system | titlecase }}
                        </mat-label>
                    </div>

                    <!--- Home Application --->
                    <div class="form-entry">
                        <mat-label class="info-title">{{ 'user.home_application' | translate }}:</mat-label>
                        <mat-label class="info-value" data-automation="home_application">
                            {{ user.home_application ? getHomeApplicationForUser() : '-' }}
                        </mat-label>
                    </div>
                </div>
            </mat-expansion-panel>
        </ng-container>
    </form>
</div>
