<ng-container *ngIf="noUsers">
    <div class="empty-page">
        <mat-icon>warning</mat-icon>
        <h2>{{ 'user.list.none_found' | translate }}</h2>
    </div>
</ng-container>

<ng-container *ngIf="loading">
    <div class="empty-page">
        <mat-spinner diameter="60"></mat-spinner>
        <h2>{{ 'user.loading' | translate }}...</h2>
    </div>
</ng-container>
