import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BusinessRole } from 'src/app/shared/model/business-role.model';
import { PermissionService } from '../../../shared/services/permissions.service';
@Component({
  selector: 'app-role-chip',
  templateUrl: './role-chip.component.html',
  styleUrls: ['./role-chip.component.scss'],
})
export class RoleChipComponent implements OnInit, OnDestroy {
  @Input() role: BusinessRole;
  @Input() isEdit: boolean;
  @Input() spaceId: string;
  @Input() userId: string;
  @Input() status: string;
  @Input() newRole: boolean;
  @Input() saving: boolean;
  @Input() toDelete: boolean;

  showDelete = false;

  subscriptions: Subscription[] = [];

  get loading(): boolean {
    return this.status === 'LOADING';
  }
  get success(): boolean {
    return this.status === 'SUCCESS';
  }
  get failed(): boolean {
    return this.status === 'FAILED';
  }

  private stats = ['LOADING','SUCCESS','FAILED'];
  updateStatus(status: string) {
    if (this.stats.includes(status)) {
      this.status = status;
    }
  }

  @Output() removeRoleEvent = new EventEmitter<BusinessRole>();

  constructor(private permissionsService: PermissionService) {}

  removeRole() {
    this.removeRoleEvent.emit(this.role);
  }

  ngOnInit() {
    const permissionSub = this.permissionsService.permissions$.subscribe(permissions => {
      if (permissions && this.permissionsService.canDeleteRoles()) {
        this.showDelete = true;
      }
    });

    this.subscriptions.push(permissionSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
