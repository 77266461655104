export enum Permission {
  create = 'CREATE',
  read = 'READ',
  update = 'UPDATE',
  delete = 'DELETE',
}

export enum Resource {
  usersManagement = 'usersManagement',
  manageUsers = 'manageUsers',
  profile = 'profile',
}

export type PermissionType = {
  [key: string]: string[];
};

export const ViewPermission = {
  usersManagement: ['READ'],
  profile: ['READ'],
};

export const EditPermission = {
  usersManagement: ['UPDATE'],
  profile: ['UPDATE'],
};

export const CreatePermission = {
  usersManagement: ['CREATE'],
  profile: ['NO'],
};
