import { Injectable } from '@angular/core';
import { AdminGroup } from '../model/admin-group';
import { BehaviorSubject } from 'rxjs';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';

@Injectable()
export class AdminGroupService {
  groups$: BehaviorSubject<AdminGroup[]>;

  constructor(private apiService: ApiService) {
    this.groups$ = new BehaviorSubject<AdminGroup[]>([]);
  }

  async getAdminGroups() {
    try {
      const groups = await this.apiService.get(`admin-groups`);
      const convertedGroups: AdminGroup[] = convertKeys.toCamel(groups);
      this.groups$.next(convertedGroups);
      return convertedGroups;
    } catch (err) {
      console.log('Could not load groups.', err);
    }
  }
}
