import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { UntypedFormGroup } from '@angular/forms';
import { UserFormComponent } from '../user-form/user-form.component';
import { UserService } from '../shared/services/user.service';
import { UserPost, Address, Phone } from '../shared/model/user.model';
import { Subscription } from 'rxjs';
import { UserStatusService } from '../shared/services/user-status.service';
import { CountriesService } from '../shared/services/countries.service';
import { untypedPrefix } from "@angular/core/schematics/migrations/typed-forms/util";

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss', '../shared/shared.styles.scss'],
})
export class CreateComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  SUCCESS = 'Created Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_CREATED = 'Oops, There was a problem creating your user';
  REQUIRED = 'required';
  appPrefix = 'usr';
  appData: any = {};
  userform: UntypedFormGroup;
  orgId = '';
  selectedId = {};
  isSubmitting = false;
  activateOnSubmit = false;
  readonly mode = 'create';

  private userFormComponentView: UserFormComponent;

  @ViewChild(UserFormComponent, { static: true })
  set userFormComponent(userFormComponent: UserFormComponent) {
    this.userFormComponentView = userFormComponent;
  }

  get userFormComponent() {
    return this.userFormComponentView;
  }

  userForm: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private messageService: GlobalAlertService,
    private userService: UserService,
    private contextSelectorService: ContextSelectorService,
    private statusService: UserStatusService,
    private countriesService: CountriesService,
  ) {
    this.selectedId = this.route?.snapshot?.params?.selectedId;

    this.translateService.get('user.notification.created_successfully').subscribe((result: string) => {
      this.SUCCESS = result;
      this.BAD_REQUEST = this.translateService.instant('user.notification.bad_request');
      this.NOT_CREATED = this.translateService.instant('user.notification.not_created');
      this.REQUIRED = this.translateService.instant('user.validation.required');
    });

    const orgSub = this.contextSelectorService.currentContext$.subscribe((orgs: Context[]) => {
      this.orgId = orgs[0].id;
    });
    this.subscriptions.push(orgSub);
  }

  ngOnInit() {
    this.userform = new UntypedFormGroup({});
  }

  handleCancel() {
    this.ngxDeeplinkerService.returnHandler({ appPrefix: this.appPrefix });
    if (this.selectedId) {
      this.router.navigate([`details/${this.selectedId}/view`], {});
    } else {
      this.router.navigate(['/']);
    }
  }

  async handleSubmit(edit = false) {
    if (!this.userform.valid) {
      this.messageService.setError(this.REQUIRED);
      return;
    }

    this.isSubmitting = true;

    try {
      let addresses: Address[] = this.userFormComponent.user.addresses.filter(this.userService.filterAddress);

      if (addresses && addresses.length === 0) {
        addresses = undefined;
      } else {
        addresses.forEach(address => {
          if (address.country) {
            address.country_name = this.countriesService.getCountryNameFromId(address.country);
          }
        });
      }

      let phones: Phone[] = this.userFormComponent.user.phones.filter(this.userService.filterPhones);


      phones = phones.map((phone: any) => {
        return {
          primary: phone.primary,
          phone_type: phone.phone_type || 'WORK',
          phone_number: phone.phone_number.replace(/[^0-9]/gi, ''),
          extension: phone.extension,
          country_code: phone.country_code.replace(/[^0-9]/gi, ''),
        };
      });

      if (phones && phones.length === 0) {
        phones = undefined;
      }
      const newUser: UserPost = {
        sync_to_ecrm: this.userFormComponent.user.sync_to_ecrm,
        username: this.userFormComponent.user.username.trim(),
        first_names: this.userFormComponent.user.first_names,
        last_names: this.userFormComponent.user.last_names,
        email: this.userFormComponent.user.email.trim(),
        phones,
        addresses,
        title: this.userFormComponent.user.title.trim() || '',
        user_type: this.userFormComponent.user.userType,
        user_class: this.userFormComponent.user.userClass,
        locale: this.userFormComponent.user.locale,
        timezone: this.userFormComponent.user.timezone,
        brand: this.userFormComponent.user.brand || undefined,
        status: 'Pending',
        unit_system: this.userFormComponent.user.unit_system,
        home_application: this.userFormComponent.user.home_application,
        ecrm_id: '',
        contact_id: this.userFormComponent.user.contact_id,
        organization: this.orgId,
        primary_contact: this.userFormComponent.user.primary_contact,
        twentyfour_seven_contact: this.userFormComponent.user.twentyfour_seven_contact,
      };

      const response = await this.userService.createUser(newUser);
      if (this.activateOnSubmit) {
        await this.statusService.updateUserStatus(response.id, 'WELCOME');
      }
      this.ngxDeeplinkerService.returnHandler({ appPrefix: this.appPrefix, callbackValue: response.id });
      this.messageService.setSuccess(this.SUCCESS);
      setTimeout(() => {
        this.userService.refetchUsers();
        // this.userService.selectedUserId$.next(response.id);
        this.router.navigate([`details/${response.id}/${edit ? 'edit' : 'view'}`], {});
      }, 0);
    } catch (e) {
      console.log(e);
      const errorMessage = e.error.message;
      if (errorMessage === 'ERR_BAD_REQUEST') {
        this.messageService.setError(this.BAD_REQUEST);
      } else {
        this.messageService.setError(this.NOT_CREATED);
      }
    } finally {
      this.isSubmitting = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  onUserClassChange(event:boolean) {
    if(event && this.activateOnSubmit) {
      this.activateOnSubmit = false;
    }
  }
}
