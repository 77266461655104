/* * * ANGULAR * * */
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CommonModule, APP_BASE_HREF } from '@angular/common';

/* * * 3RD PARTY * * */
import { TranslateLoader, TranslateModule, TranslateService, TranslateStore } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TreeViewModule } from '@progress/kendo-angular-treeview';

/* * * SHARED * * */
import { GlobalFooterModule } from 'ngx-global-contact-footer';
import { GlobalNavModule } from 'ngx-global-nav';
import { MultiLocaleModule } from 'multi-locale-input';
import { NgxDeeplinkerModule } from 'ngx-deeplinker';
import { TreeSelectorModule } from 'ngx-tree-selector';
import { AnalyticsService, CookieService as NgxCookieService, NOC_ANALYTICS } from 'ngx-shared-services';
import { analytics } from 'noc-analytics';
import { CookieService } from './shared/services/cookie.service';
import { GlobalAlertService } from './shared/services/global-alert.service';
import { GlobalAlertModule } from './global-alert/global-alert.module';
import { NavService } from './shared/services/global-nav.service';
import { I18nService } from './shared/services/i18n.service';

import { AuthHttpInterceptor } from './services/auth.interceptor';
import { MockHttpInterceptor } from './services/mock.interceptor';
import { SelectValidator } from './shared/directives/select-valid.directive';
import { SubscriptionTableComponent } from './shared/components/subscription-table.component';

/* * * COMPONENTS * * */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateComponent } from './create/create.component';
import { ListComponent } from './list/list.component';
import { EditComponent } from './edit/edit.component';
import { ViewComponent } from './view/view.component';
import { DefaultComponent } from './default/default.component';
import { ActiveLocationRolesComponent } from './user-form/active-location-roles/active-location-roles.component';
import { UpdateStatusDialogComponent } from './dialogs/update_status/update-status-dialog.component';
import { DeleteRolesDialogComponent } from './dialogs/delete_roles/delete-roles-dialog.component';
import { UserStatusBarComponent } from './user-status-bar/user-status-bar.component';
import { UserUpdateDialogComponent } from './dialogs/update_roles/user-update-dialog.component';

/* * * SERVICES * * */
import { ApiService } from './shared/services/api.service';
import { DeeplinksService } from './shared/services/deeplinks.service';
import { LocalesService } from './shared/services/locales.service';
import { UserStatusService } from './shared/services/user-status.service';
import { BrandsService } from './shared/services/brands.service';
import { MeasurementService } from './shared/services/measurement.service';
import { BusinessRolesService } from './shared/services/business-roles.service';
import { ApplicationsService } from './shared/services/applications.service';
import { TimezonesService } from './shared/services/timezones.service';
import { SpaceService } from './shared/services/space.service';
import { SidenavService } from './shared/services/sidenav.service';
import { AdminGroupService } from './shared/services/admin-group.service';
import { OrganizationsService } from './shared/services/organizations.service';
import { DrGroupService } from './shared/services/dr-group.service';
import { ContactSubscriptionService } from './shared/services/contact-subscriptions.service';
import { FormValidatorService } from './shared/services/form-validator.service';
import { UserUpdateDialogService } from './shared/services/user-update-dialog.service';
import { NgForm } from '@angular/forms';
/* * * MATERIAL * * */
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';

import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { UserFormComponent } from './user-form/user-form.component';
import { UsertypeService } from './shared/services/usertype.service';
import { CountriesService } from './shared/services/countries.service';
import { PhoneNumberPipe } from './shared/pipes/phone.number.pipe';
import { OrganizationPrimaryPipe } from './shared/pipes/organization.pipe';
import { UserNamePipe } from './shared/pipes/username.pipe';
import { TranslateObjectPipe } from './shared/pipes/translate.obj.pipe';
import { LocationRolesComponent } from './user-form/location-roles/location-roles.component';
import { ActiveLocationComponent } from './user-form/active-location-roles/active-location/active-location.component';
import { RoleChipComponent } from './user-form/active-location-roles/role-chip/role-chip.component';
import { PermissionService } from './shared/services/permissions.service';
import { ResourceService } from './services/resource.service';
import { ItsService } from './shared/services/its.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SubmitRolesDialogComponent } from './dialogs/submit-roles-dialog/submit-roles-dialog.component';
import { MatPaginatorModule } from '@angular/material/paginator';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

export function getBaseLocation() {
  const cookies = {};
  document.cookie.split(';').map((variable) => {
    const variableParts = variable.split('=');
    cookies[variableParts[0].trim()] = variableParts[1];
  });
  const urlParts = location.pathname.split('?');
  const paths = urlParts[0].split('/');
  const pathBrand = paths[1];
  const defaultBrand = 'enelx';
  const brand = (cookies['default_brand'] || '').toLowerCase();

  if (brand && pathBrand !== brand && brand !== defaultBrand) {
    return `/${brand}`;
  } else if (pathBrand === brand) {
    return `/${brand}`;
  } else {
    return `/`;
  }
}
@NgModule({
  declarations: [
    AppComponent,
    ListComponent,
    CreateComponent,
    EditComponent,
    UserFormComponent,
    ViewComponent,
    DefaultComponent,
    ActiveLocationRolesComponent,
    SelectValidator,
    PhoneNumberPipe,
    UserNamePipe,
    OrganizationPrimaryPipe,
    TranslateObjectPipe,
    LocationRolesComponent,
    RoleChipComponent,
    ActiveLocationComponent,
    RoleChipComponent,
    UpdateStatusDialogComponent,
    UserStatusBarComponent,
    DeleteRolesDialogComponent,
    UserUpdateDialogComponent,
    SubscriptionTableComponent,
    SubmitRolesDialogComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    GlobalNavModule.forRoot({ namespace: 'ngx-global-nav.' }),
    GlobalFooterModule.forRoot({ namespace: 'ngx-global-contact-footer.' }),
    GlobalAlertModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    AppRoutingModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatTreeModule,
    MatInputModule,
    MatChipsModule,
    NgxDeeplinkerModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatIconModule,
    MatCheckboxModule,
    TreeSelectorModule,
    MatButtonModule,
    MatDialogModule,
    MatSidenavModule,
    MatAutocompleteModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatListModule,
    MatMenuModule,
    MatSlideToggleModule,
    TreeViewModule,
    MatTooltipModule,
    MultiLocaleModule,
    ScrollingModule,
    MatRadioModule,
    MatPaginatorModule,
    MatToolbarModule
  ],
  providers: [
    TranslateService,
    AnalyticsService,
    NgxCookieService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    HttpClient,
    ApiService,
    GlobalAlertService,
    I18nService,
    UserStatusService,
    BrandsService,
    MeasurementService,
    BusinessRolesService,
    ApplicationsService,
    TimezonesService,
    LocalesService,
    UsertypeService,
    CountriesService,
    DeeplinksService,
    SpaceService,
    AdminGroupService,
    MatDatepickerModule,
    SidenavService,
    NavService,
    OrganizationsService,
    ContactSubscriptionService,
    UserUpdateDialogService,
    ItsService,
    DrGroupService,
    FormValidatorService,
    NgForm,
    PermissionService,
    ResourceService,
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: true,
        strict: true,
      },
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: NOC_ANALYTICS, useValue: analytics },
    TranslateStore,
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseLocation,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
