<ng-container>
    <table mat-table [dataSource]="dataSource" [ngClass]="{ stickyTableHeader: !noUsers || !loading }">
        <!--  Name / <email> (<username>) Column -->
        <ng-container matColumnDef="name/email">
            <th mat-header-cell *matHeaderCellDef>{{ 'user.list.table.heading.name' | translate }}</th>
            <td mat-cell *matCellDef="let element">
                <div class="user-name ellipsis">{{ element.lastName }}, {{ element.firstName }}</div>
                <div class="user-email ellipsis" title="{{ element.email | usernamePipe: element.username }}">
                    {{ element.email | usernamePipe: element.username }}
                </div>
            </td>
        </ng-container>

        <!--  Status Column -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <div
                    *ngIf="!element.status; else hasStatus"
                    class="status-icon status-icon-pending"
                    title="{{ 'user.list.status.unknown' | translate }}"
                ></div>
                <ng-template #hasStatus>
                    <ng-container [ngSwitch]="element.status">
                        <div
                            *ngSwitchCase="'Active'"
                            class="status-icon status-icon-active"
                            title="{{ getLocalizedStatus(element.status) }}"
                        ></div>
                        <div
                            *ngSwitchCase="'Inactive'"
                            class="status-icon status-icon-inactive"
                            title="{{ getLocalizedStatus(element.status) }}"
                        ></div>
                        <div
                            *ngSwitchCase="'Unverified'"
                            class="status-icon status-icon-pending"
                            title="{{ getLocalizedStatus(element.status) }}"
                        ></div>
                        <div
                            *ngSwitchCase="'Pending'"
                            class="status-icon status-icon-pending"
                            title="{{ getLocalizedStatus(element.status) }}"
                        ></div>
                        <div
                            *ngSwitchCase="'Deactivated'"
                            class="status-icon status-icon-inactive"
                            title="{{ getLocalizedStatus(element.status) }}"
                        ></div>
                        <div
                            *ngSwitchCase="'Terminated'"
                            class="status-icon status-icon-inactive"
                            title="{{ getLocalizedStatus(element.status) }}"
                        ></div>
                    </ng-container>
                </ng-template>
            </td>
        </ng-container>

        <tr
            mat-row
            [attr.data-user-id]="row.id"
            *matRowDef="let row; let isEven = even; columns: displayedColumns"
            class="user-list-row"
            [ngClass]="{ selectedUser: row.id === selectedUser }"
            (click)="onRowClicked(row.id)"
        >
            <div #selectedUserItem *ngIf="row.id === selectedUser"></div>
        </tr>
    </table>

    <ng-container *ngIf="noUsers && !loading">
        <div class="list-empty">
            {{ 'user.list.none_configured' | translate }}
        </div>
    </ng-container>

    <div class="spinner-container" *ngIf="loading">
        <mat-spinner mode="indeterminate" diameter="60"></mat-spinner>
    </div>

    <div class="create-button-container">
        <button
            (click)="routeToCreatePage()"
            id="create-button"
            data-automation="create-button"
            class="icon-btn x-btn-primary"
        >
            <mat-icon>add</mat-icon>
            <span class="btn-text">{{ 'user.list.button.create_user' | translate }}</span>
        </button>
    </div>
</ng-container>
