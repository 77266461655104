import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserStatus } from '../shared/model/user.model';
import { StatusUpdateActions, StatusToActionsList } from '../shared/model/status.model';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserStatusService } from '../shared/services/user-status.service';
import { UpdateStatusDialogComponent } from '../dialogs/update_status/update-status-dialog.component';
import { Permission, PermissionType } from '../shared/model/permission.model';
import { PermissionService } from '../shared/services/permissions.service';

@Component({
  selector: 'app-user-status-bar',
  templateUrl: './user-status-bar.component.html',
  styleUrls: ['./user-status-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserStatusBarComponent implements OnInit, OnDestroy {
  @Input() status: UserStatus;
  @Input() userId: string;
  @Input() selectedOrg: string;

  Permission = Permission;
  permissions: Permission[];
  statusToActionsList = StatusToActionsList;
  canUpdate = false;
  subscriptions: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private userStatusService: UserStatusService,
    private translateService: TranslateService,
    private authGuard: AuthGuard,
    private permissionService: PermissionService,
  ) {}

  ngOnInit() {
    const permissionSub = this.permissionService.permissions$.subscribe((permissions: PermissionType) => {
      if (permissions && this.permissionService.canUpdate()) {
        this.canUpdate = true;
      }
    });

    this.subscriptions.push(permissionSub);
  }

  getLocalizedButtonLabel(buttonLabel) {
    return this.translateService.instant(`user.update_status.${buttonLabel}`);
  }

  getStatusForUser(status: UserStatus) {
    return this.userStatusService.getTranslatedStatus(status);
  }

  isActionableStatus() {
    return this.statusToActionsList.hasOwnProperty(this.status);
  }

  openUpdateStatusDialog(action: StatusUpdateActions): void {
    this.dialog.open(UpdateStatusDialogComponent, {
      width: '400px',
      data: {
        action: action,
        userId: this.userId,
        orgId: this.selectedOrg,
      },
      disableClose: true,
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
