<div class="app-user-create content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon>library_add</mat-icon>
                </div>
                <h1 id="create-title" data-automation="createTitle">{{ 'user.create.page_title' | translate }}</h1>
                <p class="subtitle" data-automation="create-subtitle">{{ 'user.create.subtitle' | translate }}</p>
            </div>
        </div>

        <div class="right-column">
            <button
                class="icon-btn x-btn-secondary"
                aria-label="Cancel"
                (click)="handleCancel()"
                data-automation="userCancel"
            >
                <mat-icon>clear</mat-icon>
                <span class="btn-text">{{ 'user.button.cancel' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                aria-label="Submit and add roles"
                (click)="handleSubmit(true)"
                [disabled]="isSubmitting || create.invalid"
                type="submit"
                data-automation="submitAddRoles"
            >
                <mat-icon *ngIf="!isSubmitting">playlist_add_check</mat-icon>
                <div *ngIf="isSubmitting" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text">{{ 'user.button.submit_add_roles' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                aria-label="Submit"
                (click)="handleSubmit()"
                [disabled]="isSubmitting || create.invalid"
                type="submit"
                data-automation="userSubmit"
            >
                <mat-icon *ngIf="!isSubmitting">done</mat-icon>
                <div *ngIf="isSubmitting" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text">{{ 'user.button.submit' | translate }}</span>
            </button>
        </div>
    </header>

    <mat-list class="float-right">
        <mat-list-item outline color="primary" class="status-item">
            <mat-checkbox
                class="dark-text-cbox"
                name="activateOnSubmit"
                data-automation="activate-on-submit"
                [(ngModel)]="activateOnSubmit"
                [disabled]="userFormComponent.isSystemUser()"
                >{{ 'user.create.activate_on_submit' | translate }}</mat-checkbox
            >
        </mat-list-item>
    </mat-list>

    <form #create="ngForm" class="app-user-create" data-app-prefix="usr">
        <app-user-form [mode]="mode" [appData]="appData" (onUserClassChange)="onUserClassChange($event)"> </app-user-form>
    </form>
</div>
