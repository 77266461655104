import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { Timezone } from '../model/timezone.model';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TimezonesService {
  timezones: Timezone[] = [];
  timezones$: BehaviorSubject<Timezone[]> = new BehaviorSubject<Timezone[]>([]);

  constructor(private apiService: ApiService) {
    this.getTimezones();
  }

  async getTimezones() {
    const rawResp = await this.apiService.get('timezones');
    if (this.timezones && Array.isArray(this.timezones)) {
      while (this.timezones.length > 0) {
        this.timezones.pop();
      }
    }
    if (typeof rawResp !== undefined && Array.isArray(rawResp) && rawResp.length > 0) {
      rawResp.sort((a, b) => Number(a.display_seq) - Number(b.display_seq));
      this.timezones.push(...convertKeys.toCamel<Timezone[]>(rawResp));
    }
    this.timezones$.next(this.timezones);
    return this.timezones;
  }
}
