import { Component, ViewChild, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { SpaceRole, UserRole } from 'src/app/shared/model/business-role.model';
import { BusinessRolesService } from '../../shared/services/business-roles.service';
import { ContactSubscriptionService } from '../services/contact-subscriptions.service';
import { SpaceService } from '../services/space.service';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { SiteItem } from '../model/site.model';
import { ContactSubscription, SubscriptionTable } from '../model/subscription-table.model';
import { Organization, OrgSourceGroup, SpaceGroup } from '../model/organization-space-group.model';
import { ActivatedRoute } from '@angular/router';

const rolesToSub = ['DR_GENERAL_USER', 'DR_ENERGY_USER', 'IRISH_MARKET_USER'];
const NOTIFICATION_SUBSCRIPTIONS = ['NOTIFY_SMS', 'NOTIFY_PHONE', 'NOTIFY_EMAIL'];
enum MODE {
  EDIT = 'edit',
  VIEW = 'view',
}
enum CommunicationType {
  sms = 'NOTIFY_SMS',
  voice = 'NOTIFY_PHONE',
  email = 'NOTIFY_EMAIL',
}

export interface SubscriptionObject {
  subscriptionId: string;
  entityId: string;
  entityType: string;
  name: string;
  sms: boolean;
  voice: boolean;
  email: boolean;
}

@Component({
  selector: 'app-subscription-table',
  templateUrl: './subscription-table.component.html',
  styleUrls: ['./subscription-table.component.scss', '../shared.styles.scss'],
})
export class SubscriptionTableComponent implements OnInit {

  displayedColumns: string[] = ['site', 'text', 'voice', 'email'];
  @Input() mode: string;
  oldData: SubscriptionObject[] = [];
  @Input() userId: string;
  public dataSource: MatTableDataSource<SubscriptionTable> = new MatTableDataSource();
  private paginator: MatPaginator;
  contactSubscriptions: any[];
  flattenedMapOfTypes: Map<string, any>;
  accessibleSites$: BehaviorSubject<SiteItem[]> = new BehaviorSubject(null);

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;

    if (this.paginator) {
      this.applyFilter('');
    }
  }
  totalRecords = 0;
  pageSize = 10;
  loading = false;
  loadingLocationsAndRoles: boolean;
  spacesWithRoles: SpaceRole[] = [];
  destroySubject$: Subject<void> = new Subject();
  searchText = '';

  @Input() hasAnyPhoneField: boolean;
  @Input() hasAnyMobilePhoneField: boolean;
  @Input() hasEmailField: boolean;
  @Output() addSubscriptionEvent = new EventEmitter<string>();
  @Output() delSubscriptionEvent = new EventEmitter<string>();
  // subscriptions: SubscriptionObject[] = [];
  EDIT = 'edit';
  subscriptions = [];
  subs: any [] = [];
  userRoles: UserRole[] = [];

  @ViewChild('matRefDispatch') matRefDispatch: MatSelect;
  @ViewChild('matRefText') matRefText: MatSelect;
  @ViewChild('matRefVoice') matRefVoice: MatSelect;
  @ViewChild('matRefEmail') matRefEmail: MatSelect;

  allComplete = false;
  bulkSubActionsLabelAssign = '';
  bulkSubActionsLabelUnassign = '';
  bulkActionTypeLabelAssign = '';
  bulkActionTypeLabelUnassign = '';
  bulkSubActions = [];
  bulkSubActionsText = [];
  bulkSubActionsVoice = [];
  bulkSubActionsEmail = [];

  updateAllComplete() {
    this.allComplete = this.subs != null && this.subs.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.subs == null) {
      return false;
    }
    return this.subs.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.subs == null) {
      return;
    }
    this.subs.forEach(t => (t.completed = completed));
    if (this.subs.filter(t => t.completed).length == 0) {
      this.clear();
    }
  }

  toggleSiteCompleted(site) {
    console.log(site);
  }

  get isEditMode() {
    return this.mode === MODE.EDIT;
  }

  get isViewMode() {
    return this.mode === MODE.VIEW;
  }

  constructor(
    private contactSubscriptionService: ContactSubscriptionService,
    private businessRolesService: BusinessRolesService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private spaceService: SpaceService,
  ) {
    this.bulkSubActionsLabelAssign = this.translateService.instant('user.table.bulk_sub_actions_label_assign');
    this.bulkSubActionsLabelUnassign = this.translateService.instant('user.table.bulk_sub_actions_label_unassign');
    this.bulkActionTypeLabelAssign = this.translateService.instant('user.table.bulk_action_type_label_assign');
    this.bulkActionTypeLabelUnassign = this.translateService.instant('user.table.bulk_action_type_label_unassign');

    this.bulkSubActions = [
      { value: 'bulk-action-assign-all', viewValue: this.bulkSubActionsLabelAssign },
      { value: 'bulk-action-unassign-all', viewValue: this.bulkSubActionsLabelUnassign }
    ];

    this.bulkSubActionsText = [
      { value: 'bulk-action-text-assign', viewValue: this.bulkActionTypeLabelAssign },
      { value: 'bulk-action-text-unassign', viewValue: this.bulkActionTypeLabelUnassign }
    ];

    this.bulkSubActionsVoice = [
      { value: 'bulk-action-voice-assign', viewValue: this.bulkActionTypeLabelAssign },
      { value: 'bulk-action-voice-unassign', viewValue: this.bulkActionTypeLabelUnassign }
    ];

    this.bulkSubActionsEmail = [
      { value: 'bulk-action-email-assign', viewValue: this.bulkActionTypeLabelAssign },
      { value: 'bulk-action-email-unassign', viewValue: this.bulkActionTypeLabelUnassign }
    ];
  }

  ngOnInit(): void {

    this.spaceService.getFlattenedMapOfTypes(['organization', 'spacegroup', 'site']);
   
    const sitesRolesSub = combineLatest([
      this.businessRolesService.userRoles$,
      this.spaceService.flattenedMapOfTypes$,
      this.contactSubscriptionService.contactSubscriptions$,
    ]).subscribe(([userRoles, flattenedMapOfTypes, subscriptions]) => {
      if (userRoles && flattenedMapOfTypes) {
        this.getListOfAccessibleSites(userRoles, flattenedMapOfTypes, subscriptions);
      }
    });

    const siteSubscriptionsSub = combineLatest([
      this.contactSubscriptionService.contactSubscriptions$,
      this.spaceService.accessibleSites$
    ]).subscribe(([subscriptions, accessibleSites]) => {
      if (subscriptions && accessibleSites) {
        this.buildSubscriptionTableData(accessibleSites, subscriptions);
      }
    });

    const loadingSubs = combineLatest([
      this.contactSubscriptionService.loading$,
      this.businessRolesService.loading$,
      this.spaceService.loading$,
    ]).subscribe(([subscriptionsLoading, userRolesLoading, flattenedMapOfTypesLoading]) => {
      if (!subscriptionsLoading && !userRolesLoading && !flattenedMapOfTypesLoading) {
        this.loading = false;
      } else {
        this.loading = true;
      }
    });

    this.subscriptions.push(...[sitesRolesSub, loadingSubs, siteSubscriptionsSub]);
  }

  private getListOfAccessibleSites(
    userRoles: UserRole[],
    flattenedMapOfTypes: Map<string, any>,
    subscriptions: ContactSubscription[] = [],
  ) {
    const entityListWithRoles = userRoles
      .filter((role) => rolesToSub.includes(role.businessRole))
      .map((role) => role.entityIds)
      .flat();

    const subscriptionsSites = this.getSubscriptionSites(subscriptions, flattenedMapOfTypes);
    const listSites = this.getListOfEntities(entityListWithRoles, 'Site') as SiteItem[];
    const listOrgs = this.getListOfEntities(entityListWithRoles, 'Organization') as Organization[];
    const listSpaceGroups = this.getListOfEntities(entityListWithRoles, 'SpaceGroup') as SpaceGroup[];
    const sitesFromOrgs = this.sitesFromEntitiesWithChildren(listOrgs, flattenedMapOfTypes);
    const sitesFromSpaceGroups = this.sitesFromEntitiesWithChildren(listSpaceGroups, flattenedMapOfTypes);
    const finalSetOfSites: Set<SiteItem> = new Set([
      ...listSites,
      ...sitesFromOrgs,
      ...sitesFromSpaceGroups,
      ...subscriptionsSites,
    ]);
    this.spaceService.updateAccessibleSites(Array.from(finalSetOfSites));
  }

  getSubscriptionSites(subscriptions: ContactSubscription[], flattenedMapOfTypes: Map<string, any>): SiteItem[] {
    return subscriptions
      .filter(
        (sub) => NOTIFICATION_SUBSCRIPTIONS.includes(sub.communicationName) && sub.entityType.toLowerCase() === 'site',
      )
      .map((subscription) => {
        return flattenedMapOfTypes.get(subscription.entityId);
      });
  }

  buildSubscriptionTableData(finalListOfSetOfSites: SiteItem[], subscriptions: ContactSubscription[]): void {
    // Step 1: Initialize the site map with basic data
    const siteMap = this.initializeSiteMap(finalListOfSetOfSites);
    
    // Step 2: Update the map with subscription details
    const notifySubscriptions = subscriptions.filter((sub) =>
        NOTIFICATION_SUBSCRIPTIONS.includes(sub.communicationName),
    );
    
    this.applySubscriptionsToSiteMap(siteMap, notifySubscriptions);
  
    // Step 3: Convert the map to an array, sort it, and prepare the data source
    const sortedSubscriptionTable = this.prepareSubscriptionTable(siteMap);

    // Step 4: Set up the table data source and paginator
    this.setupTableDataSource(sortedSubscriptionTable);
  }

  private initializeSiteMap(finalListOfSetOfSites: SiteItem[]): Map<string, any> {
    const siteMap = new Map<string, any>();
    finalListOfSetOfSites.forEach((site: SiteItem) => {
      if (site) {
        siteMap.set(site.id, {
          tag: site.id,
          subscriptionId: null,
          name: site.displayLabel ? site.displayLabel : site.name,
          sms: false,
          voice: false,
          email: false,
        });
      }
    });
    return siteMap;
  }

  private applySubscriptionsToSiteMap(siteMap: Map<string, any>, subscriptions: ContactSubscription[]): void {
    subscriptions.forEach((sub: ContactSubscription) => {
      const matchingSite = siteMap.get(sub.entityId);
      if (matchingSite) {
        // matchingSite.subscriptionId = sub.subscriptionId;
        matchingSite.entityId = sub.entityId;
        matchingSite.entityType = sub.entityType;
        switch (sub.communicationName) {
          case CommunicationType.email:
            matchingSite.emailSubscriptionId = sub.subscriptionId;
            matchingSite.email = true;
            break;
          case CommunicationType.voice:
            matchingSite.voiceSubscriptionId = sub.subscriptionId;
            matchingSite.voice = true;
            break;
          case CommunicationType.sms:
            matchingSite.smsSubscriptionId = sub.subscriptionId;
            matchingSite.sms = true;
            break;
        }
      }
    });
  }

  private prepareSubscriptionTable(siteMap: Map<string, any>): SubscriptionTable[] {
    const subscriptionTable = Array.from(siteMap.values());
    return this.sortSubscriptionTable(subscriptionTable);
  }

  private setupTableDataSource(sortedSubscriptionTable: SubscriptionTable[]): void {
    let filteredSubscriptionTable;
    if (this.isViewMode) {
      filteredSubscriptionTable = sortedSubscriptionTable.filter((sub) => sub.emailSubscriptionId || sub.voiceSubscriptionId || sub.smsSubscriptionId);
    } else if (this.isEditMode) {
      filteredSubscriptionTable = sortedSubscriptionTable;
    }
    this.dataSource = new MatTableDataSource<SubscriptionTable>(filteredSubscriptionTable);
    this.totalRecords = this.dataSource.data.length;
    this.subs = this.dataSource.data;
    this.dataSource.paginator = this.paginator;

    let sentEmail = false;
    let sentVoice = false;
    let sentSms = false;
    for (let i=0; i<this.totalRecords && !(sentEmail&&sentSms&&sentVoice); i++) {
      if(filteredSubscriptionTable[i].email && !sentEmail) {
        sentEmail = true;
        this.addSubscriptionEvent.emit('email');
      }
      if(filteredSubscriptionTable[i].voice && !sentVoice) {
        sentVoice = true;
        this.addSubscriptionEvent.emit('voice');
      }
      if(filteredSubscriptionTable[i].sms && !sentSms) {
        sentSms = true;
        this.addSubscriptionEvent.emit('sms');
      }
    }
    this.oldData = filteredSubscriptionTable.map((sub) => {return {...sub}});
  }


  sortSubscriptionTable(newSub: SubscriptionTable[]): SubscriptionTable[] {
    const withSubscriptionId = newSub.filter((sub) => sub.emailSubscriptionId || sub.voiceSubscriptionId || sub.smsSubscriptionId);
    const withoutSubscriptionId = newSub.filter((sub) => !(sub.emailSubscriptionId || sub.voiceSubscriptionId || sub.smsSubscriptionId));

    withSubscriptionId.sort((a, b) => a.name.localeCompare(b.name));
    withoutSubscriptionId.sort((a, b) => a.name.localeCompare(b.name));

    return (newSub = [...withSubscriptionId, ...withoutSubscriptionId]);
  }

  sitesFromEntitiesWithChildren(
    entityWithHierarchy: OrgSourceGroup[],
    flattenedMapOfTypes: Map<string, any>,
  ): SiteItem[] {
    const listOfSites = [];

    entityWithHierarchy.forEach((entity: OrgSourceGroup) => {
      const entityHierarchy = flattenedMapOfTypes.get(entity.id);
      const sitesDescendants = this.extractSitesFromHierarchy(entityHierarchy, 'Site');
      listOfSites.push(...sitesDescendants);
    });

    return listOfSites;
  }

  extractSitesFromHierarchy(entityHierarchy: OrgSourceGroup, type: string): SiteItem[] {
    const list = [];
    if (!entityHierarchy || !entityHierarchy.children || entityHierarchy.children.length <= 0) {
      return [];
    }
    entityHierarchy.children.forEach((entity) => {
      if (entity.spaceType === type) {
        list.push(entity);
      } else if (entityHierarchy.children && entityHierarchy.children.length > 0) {
        const listTypes = this.extractSitesFromHierarchy(entity, type);
        list.push(...listTypes);
      }
    });
    return list;
  }

  updateElement(list, sub, pos) {
    switch (sub.communicationName) {
      case 'NOTIFY_EMAIL':
        list[pos].email = true;
        break;
      case 'NOTIFY_PHONE':
        list[pos].voice = true;
        break;
      case 'NOTIFY_SMS':
        list[pos].sms = true;
        break;
    }
    return list;
  }

  getListOfEntities(
    entityListWithRoles: (SiteItem | SpaceGroup | Organization)[],
    type: string,
  ): (SiteItem | SpaceGroup | Organization)[] {
    return entityListWithRoles.filter((entity) => entity.spaceType === type);
  }

  applyFilter(search: string): void {
    this.dataSource.filter = search.trim().toLowerCase();
  }
  changeBulkSubAction(type: string, action: any) {
    this.clear();
    this.subs.filter((sub) => sub.completed).forEach((sub) => {
      if (action.value?.includes('assign')) {
        sub[type] = true;
      }
      if (action.value?.includes('unassign')) {
        sub[type] = false;
      }
    });
  }

  toCamelCase(str: string): string {
    return str
      .toLowerCase()
      .replace(/[^a-z0-9]+(.)/g, (match, chr) => chr.toUpperCase())
      .replace(/^(.)/, (match, chr) => chr.toLowerCase());
  }
  onPaginateChange(event: any): void {
    if (event.pageIndex == Math.ceil(this.totalRecords / this.pageSize) - 1) {
      const data = this.dataSource.data;
      this.dataSource = new MatTableDataSource(data);
      this.totalRecords = this.dataSource.data.length;
      this.dataSource.paginator = this.paginator;
    }
  }

  changeBulkSubActions(action: any) {
    this.clear();
    this.changeBulkSubAction('sms', action);
    this.changeBulkSubAction('voice', action);
    this.changeBulkSubAction('email', action);
  }

  isChecked(sub: any) {
    return !sub.completed;
  }

  isSelectEnabled() {
    return this.subs.filter((sub) => sub.completed).length > 0;
  }

  clear() {
    this.matRefDispatch.options.forEach((data: MatOption) => data.deselect());
    this.matRefText.options.forEach((data: MatOption) => data.deselect());
    this.matRefVoice.options.forEach((data: MatOption) => data.deselect());
    this.matRefEmail.options.forEach((data: MatOption) => data.deselect());
  }

  subscriptionChanges() {
    const changes = [];
    this.dataSource.data.forEach((sub:any) => {
      const old:any = this.oldData.find((older:any) => {return older.tag === sub.tag});
      if (old) {
        if (old.sms !== sub.sms || old.voice !== sub.voice || old.email !== sub.email) {
          const newSub = {
            emailSubscriptionId: sub.emailSubscriptionId || old.emailSubscriptionId,
            voiceSubscriptionId: sub.voiceSubscriptionId || old.voiceSubscriptionId,
            smsSubscriptionId: sub.smsSubscriptionId || old.smsSubscriptionId,
            entityId: sub.entityId || old.tag,
            entityType: old.entityType || 'SITE',
          };
          if (old.sms !== sub.sms && old.sms) {
            newSub['sms'] = 'del';
          }
          else if(old.sms !== sub.sms && sub.sms) {
            newSub['sms'] = 'add';
          }
          if (old.voice !== sub.voice && old.voice) {
            newSub['voice'] = 'del';
          }
          else if(old.voice !== sub.voice && sub.voice) {
            newSub['voice'] = 'add';
          }
          if (old.email !== sub.email && old.email) {
            newSub['email'] = 'del';
          }
          else if(old.email !== sub.email && sub.email) {
            newSub['email'] = 'add';
          }

          changes.push(newSub);
        }
      }
    });
    return changes;
  }

  changeSub(type: string, change) {
    if (change.checked) {
      this.addSubscriptionEvent.emit(type);
    } else {
      if(this.dataSource.data.filter((sub) => {return sub[type]}).length === 0) {
        this.delSubscriptionEvent.emit(type);
      }
    }
  }
}
