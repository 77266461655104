import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../shared/services/user.service';
import { PermissionService } from '../shared/services/permissions.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnInit, OnDestroy {
  loading = true;
  noUsers = false;
  users;
  subscriptions: Subscription[] = [];

  constructor(private router: Router, private userService: UserService, private permissionService: PermissionService) {
    this.userService.selectedUserId$.next(null);
    let userSub;
    const permissionSub = this.permissionService.permissions$.subscribe(permissions => {
      if (permissions && !this.permissionService.canSeeOrgUsers()) {
        userSub = this.userService.loggedInUser$.subscribe(loggedUser => {
          if (loggedUser) {
            this.router.navigate([`/details/${loggedUser.userId}/view`]);
          }
        });
        this.subscriptions.push(userSub);
      }
    });

    this.subscriptions.push(permissionSub);
  }

  navigateToFirstUser(users) {
    if (users && users.length > 0) {
      this.loading = true;
      this.noUsers = false;
      this.router.navigate([`details/${users[0].id}/view`], {});
    } else {
      this.loading = false;
      this.noUsers = true;
    }
  }

  async ngOnInit() {
    if (this.userService.orgId$.value) {
      await this.userService.refetchUsers();
    }
    const supportedBrands: any = (window as any).supportedBrands;
    this.userService.users$.subscribe(users => {
      if (!users || users.length === 0) {
        this.noUsers = true;
        this.loading = false;
        return;
      }
      this.users = users;
      for (const brand of supportedBrands) {
        if (!!this.router.isActive(brand, true)) {
          this.navigateToFirstUser(users);
        }
      }
      if (!!this.router.isActive('/', true)) {
        this.navigateToFirstUser(users);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
