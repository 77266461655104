import { ContactSubscriptionService } from './../../shared/services/contact-subscriptions.service';
import { BusinessRolesService } from './../../shared/services/business-roles.service';
import { UserRole, SpaceRole, DR_ROLES_TO_SUBSCRIPTIONS } from './../../shared/model/business-role.model';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DeleteRoleData {
  roles: UserRole[];
  entity: SpaceRole;
  deleteEntityAccess: boolean;
  userId: string;
  contactId: string;
  sync: boolean;
  status: string;
  allSpaceRoles: SpaceRole[];
}

@Component({
  selector: 'app-delete-roles-dialog',
  templateUrl: './delete-roles-dialog.component.html',
  styleUrls: ['./delete-roles-dialog.component.scss'],
})
export class DeleteRolesDialogComponent {
  dialogState = 'DEFAULT';

  constructor(
    private businessRoles: BusinessRolesService,
    private contactSubscriptionService: ContactSubscriptionService,
    public dialogRef: MatDialogRef<DeleteRolesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteRoleData,
  ) {
    this.dialogRef.backdropClick().subscribe(() => this.onNoClick());
  }

  onNoClick(): void {
    if (this.dialogState == 'DEFAULT') {
      this.dialogRef.close();
    }
  }

  async handleUpdate() {
    try {
      const {
        entity: { id, spaceType },
        userId,
        status,
      } = this.data;
      if (spaceType === 'Site' && status !== 'Deactivated') {
        this.dialogState = 'DELETING_SUBSCRIPTIONS';
        const deleted_subscriptions = this.data.roles
          .filter((role) => {
            return DR_ROLES_TO_SUBSCRIPTIONS.includes(role.businessRole);
          })
          .map((role) => {
            return {
              entity_id: id,
              entity_type: spaceType,
              roles: [role.businessRole],
            };
          });
        for (const subscription of deleted_subscriptions) {
          await this.contactSubscriptionService.updateContactSubscriptions(userId, [], [subscription]);
        }
      }
      this.dialogState = 'DELETING';

      for (const role of this.data.roles) {
        await this.businessRoles.deleteRole(
          this.data.userId,
          this.data.entity.id,
          role.businessRole,
          this.data.entity.spaceType,
          this.data.contactId,
        );
      }

      this.businessRoles.getUserRoles(this.data.userId);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      // Show the success message for 2s
      this.dialogState = 'DELETE_SUCCESS';
      await new Promise((resolve) => setTimeout(resolve, 2000));
      this.businessRoles.removedAllRoles(this.data.roles);
      this.dialogRef.close();
    } catch (e) {
      // Show error message for 2s
      this.businessRoles.getUserRoles(this.data.userId);
      this.dialogState = 'DELETE_FAILED';
      await new Promise((resolve) => setTimeout(resolve, 2000));
      console.log(e);
      this.dialogRef.close();
    }
  }
}
