import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserStatusService } from '../../shared/services/user-status.service';
import { UserService } from '../../shared/services/user.service';
import { StatusUpdateActions } from './../../shared/model/status.model';

export interface StatusUpdateData {
  action: StatusUpdateActions;
  userId: string;
  orgId: string;
}

@Component({
  selector: 'app-update-status-dialog',
  templateUrl: './update-status-dialog.component.html',
  styleUrls: ['./update-status-dialog.component.scss'],
})
export class UpdateStatusDialogComponent {
  dialogState = 'DEFAULT';

  constructor(
    private router: Router,
    private statusService: UserStatusService,
    private userService: UserService,
    public dialogRef: MatDialogRef<UpdateStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StatusUpdateData,
  ) {
    this.dialogRef.backdropClick().subscribe(() => this.onNoClick());
  }

  onNoClick(): void {
    if (this.dialogState == 'DEFAULT') {
      this.dialogRef.close();
    }
  }

  async deleteContact() {
    const { flex_contact_id } = this.userService.user$.getValue();
    if (flex_contact_id) {
      try {
        this.dialogState = 'DELETING_CONTACT';
        await new Promise((resolve) => setTimeout(resolve, 1500));
        await this.userService.deleteUserContact(flex_contact_id);
        this.dialogState = 'DELETE_SUCCESS';
        await new Promise((resolve) => setTimeout(resolve, 2000));
      } catch (err) {
        console.log(err);
        this.dialogState = 'DELETE_FAILED';
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    }

    this.dialogRef.close();
    return;
  }

  async handleUpdate() {
    try {
      this.dialogState = 'UPDATING';
      // Send the request to the API
      await this.statusService.updateUserStatus(this.data.userId, this.data.action);
      // Show the success message for 2s

      this.dialogState = 'UPDATE_SUCCESS';

      await new Promise((resolve) => setTimeout(resolve, 2000));
      // If 'TERMINATE' navigate to view page

      if (this.data.action === 'DEACTIVATE') {
        await this.deleteContact();
      }
      if (this.data.action === 'TERMINATE') {
        await this.deleteContact();
        this.userService.getUsersByOrg(this.data.orgId);
        this.router.navigate(['./'], {});
        return;
      }
      // Reload User data and UsersList
      await this.userService.getUser(this.data.userId);
      this.userService.getUsersByOrg(this.data.orgId);
      this.dialogRef.close();
    } catch (e) {
      // Show error message for 2s
      // Specialized Termination roles message
      if (e.error && e.error.detail && this.data.action === 'TERMINATE') {
        if (e.error.detail.includes('Must delete all relationships for user')) {
          this.dialogState = 'TERMINATION_FAILED_ROLES';
        }
      } else {
        this.dialogState = 'UPDATE_FAILED';
      }
      console.log(e);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      this.dialogRef.close();
    }
  }
}
