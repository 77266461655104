export type UserStatus = 'Active' | 'Deactivated' | 'Inactive' | 'Unverified' | 'Pending' | 'Terminated';

export interface Email {
  primary: boolean;
  email_type: string;
  email_address: string;
}

export interface Address {
  address_1: string;
  address_2?: string;
  city: string;
  address_type: string;
  state_province: string;
  postal_code: string;
  country: string;
  country_name?: any;
  primary: boolean;
}

export interface Phone {
  primary: boolean;
  phone_type: string;
  phone_number: string;
  extension: string;
  country_code: string;
}

export interface Organization {
  primary: boolean;
  organization_id: string;
  industry: string;
  subindustry: string;
  legal_name: string;
  display_label: string;
}

export interface User {
  flex_contact_id: string;
  username: string;
  userId: string;
  userType: string;
  userClass: string;
  first_names: object;
  title: string;
  fullName: string;
  last_names: object;
  brand: string;
  locale: string;
  email: string;
  phone_number: string;
  phones: Phone[] | [];
  emails: Email[] | [];
  timezone: string;
  countryCode: string;
  addresses: Address[];
  status: UserStatus;
  unit_system: string;
  home_application: string;
  ecrm_id: string;
  contact_id: string;
  organization_name: string;
  organization_id?: string;
  twentyfour_seven_contact: boolean;
  primary_contact: boolean;
  sync_to_ecrm: boolean;
  last_sync_date?: string;

}

export const UserDefaults: User = {
  username: '',
  userId: '',
  userType: '',
  userClass: '',
  first_names: {},
  title: '',
  timezone: 'America/New_York',
  last_names: {},
  fullName: '',
  brand: 'ENELX',
  locale: 'en_US',
  email: '',
  phone_number: '',
  twentyfour_seven_contact: false,
  primary_contact: false,
  phones: [],
  emails: [],
  countryCode: '',
  addresses: [],
  status: 'Inactive',
  unit_system: 'IMPERIAL',
  home_application: '',
  ecrm_id: '',
  contact_id: '',
  flex_contact_id: '',
  organization_name: '',
  organization_id: '',
  sync_to_ecrm: false,
  last_sync_date: ''
};

export const DefaultPhoneNumber: Phone = {
  primary: true,
  phone_type: undefined,
  extension: undefined,
  country_code: undefined,
  phone_number: undefined,
};

export const DefaultAddress: Address = {
  address_1: undefined,
  address_2: undefined,
  city: undefined,
  address_type: undefined,
  state_province: undefined,
  postal_code: undefined,
  country: undefined,
  primary: true,
};

export interface UserPost {
  first_names: object;
  last_names: object;
  username: string;
  locale: string;
  timezone: string;
  user_type: string;
  user_class?: string;
  organization: string;
  title: string;
  status?: string;
  unit_system: string;
  home_application: string;
  brand?: string;
  ecrm_id?: string;
  contact_id?: string;
  email: string;
  phones?: Phone[];
  addresses?: Address[];
  twentyfour_seven_contact: boolean;
  primary_contact: boolean;
  sync_to_ecrm: boolean;
}

export interface UserPatch {
  federated_applications: [
    {
      application_name: string;
      username: string;
    },
  ];
}

export interface ContactSubscription {
  entity_id: string;
  entity_type: string;
  roles: string[];
}

export interface ContactSubscriptionPost {
  comunication_name: string;
  site_id: string;
  entity_type: string;
  contact_methods: string[];
  primary_ind: boolean;
  twentyfour_seven_ind: boolean;
  sync_to_ecrm: boolean;
}