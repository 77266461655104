import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../services/i18n.service';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | phone_number
 * Example:
 *   {{ { display_label: { en_US: "English Label" } } | translateObj:display_label }}
 *   formats to: { display_label: "English Label" }
 */
@Pipe({ name: 'translateObj' })
export class TranslateObjectPipe implements PipeTransform {
  constructor(private i18nService: I18nService) {}
  transform(obj: string, key: string): string {
    return this.i18nService.interpolate(key, obj);
  }
}
