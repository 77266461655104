<ng-container *ngIf="dialogState === 'DEFAULT'">
    <div mat-dialog-content data-automation="delete-role-dialog">
        <ng-container *ngIf="data.deleteEntityAccess; else deleteRole" data-automation="dialogText">
            <span class="dialog-title" data-automation="dialogTitle">{{
                'user.dialog.delete_roles.title' | translate
            }}</span>
            <div class="title-separator"></div>
            <mat-chip class="chip-entity">
                <span data-automation="entityName" class="dialog-item">{{ data.entity.displayLabel }}</span>
            </mat-chip>
        </ng-container>
        <ng-template #deleteRole>
            <span class="dialog-title" data-automation="dialogTitle">
                {{ 'user.dialog.delete_role.title' | translate }}
            </span>
            <div class="title-separator"></div>
            <mat-chip class="chip-entity">
                <span data-automation="roleName" class="dialog-item">{{ data.roles[0].displayLabel }}</span>
            </mat-chip>
        </ng-template>
    </div>
    <div mat-dialog-actions>
        <button
            class="x-btn-secondary dialog-secondary"
            aria-label="Cancel"
            (click)="onNoClick()"
            data-automation="dialogCancel"
        >
            <span>{{ 'user.button.cancel' | translate }}</span>
        </button>

        <button
            class="x-btn-primary dialog-primary"
            aria-label="Delete"
            (click)="handleUpdate()"
            data-automation="dialogConfirm"
        >
            <span>{{ 'user.button.remove' | translate }}</span>
        </button>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'DELETING'">
    <div mat-dialog-content>
        <div class="dialog-spinner">
            <div class="spinner-container">
                <mat-spinner diameter="24"></mat-spinner>
            </div>
            <ng-container *ngIf="data.roles.length == 1; else deletingRoles">
                <span data-automation="dialogTextDeleting">{{
                    'user.dialog.delete_roles.deleting_role' | translate
                }}</span>
            </ng-container>
            <ng-template #deletingRoles>
                <span data-automation="dialogTextDeleting">{{
                    'user.dialog.delete_roles.deleting_roles' | translate
                }}</span>
            </ng-template>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'DELETING_SUBSCRIPTIONS'">
    <div mat-dialog-content>
        <div class="dialog-spinner">
            <div class="spinner-container">
                <mat-spinner diameter="24"></mat-spinner>
            </div>
            <span data-automation="dialogTextDeleting">{{
                'user.dialog.delete_roles.deleting_subscriptions' | translate
            }}</span>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'DELETE_SUCCESS'">
    <div mat-dialog-content>
        <div class="dialog-result">
            <mat-icon class="icon-success">task_alt</mat-icon>
            <ng-container *ngIf="data.roles.length == 1; else rolesSuccess">
                <span data-automation="dialogTextSuccess">{{ 'user.dialog.delete_role.success' | translate }}</span>
            </ng-container>
            <ng-template #rolesSuccess>
                <span data-automation="dialogTextSuccess">{{ 'user.dialog.delete_roles.success' | translate }}</span>
            </ng-template>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'DELETE_FAILED'">
    <div mat-dialog-content>
        <div class="dialog-result">
            <mat-icon class="icon-failed">warning_amber</mat-icon>
            <ng-container *ngIf="data.roles.length == 1; else multipleRolesFailed">
                <span data-automation="dialogTextError">{{ 'user.dialog.delete_role.failed' | translate }}</span>
            </ng-container>
            <ng-template #multipleRolesFailed>
                <span data-automation="dialogTextError">{{ 'user.dialog.delete_roles.failed' | translate }}</span>
            </ng-template>
        </div>
    </div>
</ng-container>
