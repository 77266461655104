import { Pipe, PipeTransform } from '@angular/core';
/*
 * Takes email and username and returns only one. If they are
 * different, return '<email> (<username>)'
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | usernamePipe
 */
@Pipe({ name: 'usernamePipe' })
export class UserNamePipe implements PipeTransform {
  transform(email: string, username: string): string {
    return email === username ? email : `${email} (${username})`;
  }
}
