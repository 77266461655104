import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { SidenavService } from '../shared/services/sidenav.service';
import { UserStatusService } from '../shared/services/user-status.service';
import { UserService } from '../shared/services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnDestroy {
  displayedColumns: string[] = ['status', 'name/email'];
  dataSource = [];
  loading = true;
  noUsers = false;
  selectedUser = '';
  isInit = true;
  subscriptions: Subscription[] = [];
  initialized = false;

  constructor(
    private contextSelectorService: ContextSelectorService,
    private router: Router,
    private sidenavService: SidenavService,
    private userService: UserService,
    private messageService: GlobalAlertService,
    private statusesService: UserStatusService,
    private cdr: ChangeDetectorRef
  ) {
    this.loading = true;
    const currentContextSub = this.contextSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      if (orgs[0] && orgs[0].id) {
        this.loading = true;
        this.userService.users$.next([]);
        try {
          await this.userService.getUsersByOrg(orgs[0].id);
        } catch (e) {
          console.error(e);
          let errorMessage = '';
          if (e.error && e.error.message) {
            errorMessage = e.error.message;
          } else {
            errorMessage = 'ERR_BAD_REQUEST';
          }

          this.messageService.setError(errorMessage);
        }
        this.loading = false;
        if (!this.router.isActive('/', true) && !this.router.isActive('/create', true) && !this.isInit) {
          await this.router.navigate([`/`], {});
        }
        this.isInit = false;
      }
    });

    const usersSub = this.userService.users$.subscribe(users => {
      if (users) {
        const selected = this.userService.selectedUserId$.getValue();
        if (!selected && users.length > 0) {
          this.selectedUser = users[0]['id'];
        }
        this.dataSource = users;
        this.noUsers = users.length === 0;
        setTimeout(() => {
          document?.getElementsByClassName('selectedUser')?.[0]?.scrollIntoView();
        }, 0);
      }
    });

    const selectedUserIdSub = this.userService.selectedUserId$.subscribe(userId => {
      if (userId) {
        this.selectedUser = userId;
        this.cdr.detectChanges();
      }
    });
    this.subscriptions.push(selectedUserIdSub);

    this.subscriptions.push(...[currentContextSub, usersSub]);
  }

  getLocalizedStatus(status: string) {
    return this.statusesService.getTranslatedStatus(status);
  }

  onRowClicked(id) {
    this.sidenavService.closeIfMobile();
    this.router.navigate([`details/${id}/view`], {});
  }

  routeToCreatePage() {
    this.sidenavService.closeIfMobile();
    this.router.navigate([`create`, { selectedId: this.selectedUser }]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
