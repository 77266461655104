export class Brand {
  constructor(public name = 'ENELX', public displayLabel = 'Enel X') {}
}

export const BRANDED_USERS: string[] = [
  'DEMO',
  'CONSUMERSENERGY'
]

export const SYSTEM_USER_BLACKLIST: string[] = [
  'DEMO',
  'CONSUMERSENERGY',
  'DTE'
]