import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
@Injectable()
export class UsertypeService {
  userTypes$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(private apiService: ApiService) {
    this.getUserTypes();
  }

  async getUserTypes() {
    try {
      const userTypes: any = await this.apiService.get('user-types');
      const userTypesArr = Object.values(userTypes);
      this.userTypes$.next(userTypesArr);
    } catch (err) {
      console.log('Could not load user types.', err);
    }
  }

  filterTypesByBrand(brand: string, types: any[]): any[]{
    switch (brand) {
      case 'CONSUMERSENERGY':
        return [types.find(item => item.name === 'CONSUMERSENERGY')];
      case 'DEMO':
        return types.filter(item => item.name !== 'CONSUMERSENERGY');
      default:
        return types;
    }
  }
}
