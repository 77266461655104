<div class="app-user-edit content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon>edit</mat-icon>
                </div>
                <h1 id="edit-title">{{ 'user.edit.title' | translate }}</h1>
                <p class="desktop-only subtitle" data-automation="edit-subtitle">
                    {{ 'user.edit.subtitle' | translate }}
                </p>
            </div>
        </div>

        <div class="right-column">
            <button class="icon-btn x-btn-secondary" (click)="handleCancel()" data-automation="userCancel">
                <mat-icon>clear</mat-icon>
                <span class="btn-text"> {{ 'user.button.cancel' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                (click)="handleSubmit()"
                data-automation="userSubmit"
                [disabled]="isSubmitting || edit.invalid"
            >
                <mat-icon *ngIf="!isSubmitting">done</mat-icon>
                <div *ngIf="isSubmitting" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text">{{ 'user.button.save' | translate }}</span>
            </button>
        </div>
    </header>

    <app-user-status-bar [status]="userStatus$ | async" [userId]="selectedId" [selectedOrg]="org"></app-user-status-bar>
    <form #edit="ngForm" class="user-form">
        <app-user-form [mode]="mode" [appData]="appData"></app-user-form>
    </form>
</div>
