<ng-container *ngIf="dialogState === 'DEFAULT'">
    <h1 mat-dialog-title>{{ 'user.dialog.update.title' | translate }}</h1>
    <div mat-dialog-content>
        <p *ngIf="data.action === 'TERMINATE'; else updateMesssage" data-automation="dialogText">
            {{ 'user.dialog.terminate.text' | translate }}
        </p>
        <ng-template #updateMesssage>
            <p data-automation="dialogText">{{ 'user.dialog.update.text' | translate }}</p>
        </ng-template>
    </div>
    <div mat-dialog-actions>
        <button
            class="x-btn-secondary dialog-secondary"
            aria-label="Cancel"
            (click)="onNoClick()"
            data-automation="dialogCancel"
        >
            <span>{{ 'user.button.no' | translate }}</span>
        </button>

        <button
            class="x-btn-primary dialog-primary"
            aria-label="Delete"
            (click)="handleUpdate()"
            data-automation="dialogConfirm"
        >
            <span>{{ 'user.button.yes' | translate }}</span>
        </button>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'UPDATING'">
    <div mat-dialog-content>
        <div class="dialog-spinner">
            <div class="spinner-container">
                <mat-spinner diameter="24"></mat-spinner>
            </div>
            <span data-automation="dialogTextUpdating">{{ 'user.dialog.update.updating' | translate }}</span>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'UPDATE_SUCCESS'">
    <div mat-dialog-content>
        <div class="dialog-result">
            <mat-icon class="icon-success">task_alt</mat-icon>
            <span data-automation="dialogTextSuccess">{{ 'user.dialog.update.success' | translate }}</span>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'UPDATE_FAILED'">
    <div mat-dialog-content>
        <div class="dialog-result">
            <mat-icon class="icon-failed">warning_amber</mat-icon>
            <span data-automation="dialogTextError">{{ 'user.dialog.update.failed' | translate }}</span>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'TERMINATION_FAILED_ROLES'">
    <div mat-dialog-content>
        <div class="dialog-result">
            <mat-icon class="icon-failed">warning_amber</mat-icon>
            <span data-automation="dialogTextError">{{ 'user.dialog.update.failed_termination_roles' | translate }}</span>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'DELETING_CONTACT'">
    <div mat-dialog-content>
        <div class="dialog-spinner">
            <div class="spinner-container">
                <mat-spinner diameter="24"></mat-spinner>
            </div>
            <span data-automation="dialogTextUpdating">{{ 'user.dialog.delete.deleting_contact' | translate }}</span>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'DELETE_SUCCESS'">
    <div mat-dialog-content>
        <div class="dialog-result">
            <mat-icon class="icon-success">task_alt</mat-icon>
            <span data-automation="dialogTextSuccess">{{ 'user.dialog.delete.delete_success' | translate }}</span>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'DELETE_FAILED'">
    <div mat-dialog-content>
        <div class="dialog-result">
            <mat-icon class="icon-failed">warning_amber</mat-icon>
            <span data-automation="dialogTextError">{{ 'user.dialog.delete.deleting_contact_failed' | translate }}</span>
        </div>
    </div>
</ng-container>
